import React from "react";
import { motion } from "framer-motion";
import { cn } from "../../../lib/utils";
import { useFormStore } from "../../../store/formStore";

interface FormLayoutProps {
  children: React.ReactNode;
  className?: string;
  showProgress?: boolean;
}

export const FormLayout = ({
  children,
  className,
  showProgress = true,
}: FormLayoutProps) => {
  const { formData } = useFormStore();
  const totalSteps = 11;
  const isInterstitial = formData.currentStep === 6;

  const displayStep =
    formData.currentStep > 6 ? formData.currentStep - 1 : formData.currentStep;

  const displayTotalSteps = totalSteps - 1;

  const shouldShowProgress =
    showProgress && !isInterstitial && formData.currentStep > 0;

  return (
    <div className="min-h-screen bg-white">
      <div className="h-[100dvh] container max-w-2xl mx-auto px-4 flex flex-col">
        {shouldShowProgress && (
          <div className="py-4 sticky top-0 bg-white/95 backdrop-blur-sm z-40">
            <div className="h-1.5 w-full bg-[#F0F0F0] rounded-full overflow-hidden">
              <motion.div
                className="h-full bg-[#2C2C2C]"
                initial={{ width: 0 }}
                animate={{
                  width: `${(displayStep / displayTotalSteps) * 100}%`,
                }}
                transition={{ duration: 0.3 }}
              />
            </div>
            <p className="text-sm text-[#666666] mt-2.5">
              Step {displayStep + 1} of {displayTotalSteps}
            </p>
          </div>
        )}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className={cn(
            "flex-1 flex flex-col bg-white rounded-2xl p-4 sm:p-6 shadow-[0_4px_20px_-4px_rgba(0,0,0,0.1)] border border-[#EFEFEF]",
            isInterstitial ? "bg-transparent shadow-none border-0" : "",
            className,
          )}
        >
          {children}
        </motion.div>
      </div>
    </div>
  );
};
