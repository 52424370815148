import React from "react";
import { Input } from "../ui/input";
import { motion } from "framer-motion";

interface DateOfBirthFieldProps {
  birthdate: string;
  setField: (field: "birthdate", value: string) => void;
}

export const DateOfBirthField = ({
  birthdate,
  setField,
}: DateOfBirthFieldProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.3 }}
      className="space-y-2"
    >
      <label
        htmlFor="birthdate"
        className="block text-sm font-medium text-gray-700"
      >
        Date of Birth *
      </label>
      <Input
        id="birthdate"
        type="date"
        value={birthdate}
        onChange={(e) => setField("birthdate", e.target.value)}
        required
        className="w-full placeholder:text-gray-400 placeholder:text-sm"
        min="1923-01-01"
        max={new Date().toISOString().split("T")[0]}
        defaultValue="2002-01-01"
      />
    </motion.div>
  );
};
