import { create } from "zustand";
import { persist } from "zustand/middleware";
import { updateOnboardingResponses } from "../services/userService";

export interface FormData {
  phoneNumber: string;
  name: string;
  lastName: string;
  email: string;
  birthdate: string;
  gender: "male" | "female" | "other" | null;
  height: string;
  currentLocation: string;
  hometown: string;
  targetGender: "male" | "female" | "everyone" | null;
  instagram: string;
  photos: string[] | null;
  onboardingResponses: {
    education: string;
    profession: string;
    companyName?: string;
    linkedinProfile?: string;
    aboutYourself?: string;
    partnerPreferences?: string;
    coreValues: string[];
    lifeApproach: string[];
    workBalance: string[];
    familyPreferences: string[];
    culturalBeliefs: string[];
    decisionMaking: string[];
    lifestyle: string[];
  };
  referralSource: string;
  referralCode?: string;
  currentStep: number;
  isVerified: boolean;
  lastSaved: string;
}

const initialState: FormData = {
  phoneNumber: "",
  name: "",
  lastName: "",
  email: "",
  birthdate: "",
  gender: null,
  height: "",
  currentLocation: "",
  hometown: "",
  targetGender: null,
  instagram: "",
  photos: null,
  onboardingResponses: {
    education: "",
    profession: "",
    companyName: "",
    linkedinProfile: "",
    aboutYourself: "",
    partnerPreferences: "",
    coreValues: [],
    lifeApproach: [],
    workBalance: [],
    familyPreferences: [],
    culturalBeliefs: [],
    decisionMaking: [],
    lifestyle: [],
  },
  referralSource: "",
  referralCode: "",
  currentStep: 1,
  isVerified: false,
  lastSaved: new Date().toISOString(),
};

export const useFormStore = create(
  persist<{
    formData: FormData;
    setFormData: (data: FormData) => void;
    setField: (field: keyof FormData, value: any, sessionId?: string) => void;
    setOnboardingField: (
      field: keyof FormData["onboardingResponses"],
      value: any,
      sessionId?: string,
    ) => void;
    nextStep: () => void;
    prevStep: () => void;
    resetForm: () => void;
  }>(
    (set) => ({
      formData: initialState,
      setFormData: (data: FormData) => set({ formData: data }),
      setField: (field, value, sessionId) => {
        const updateData = {
          [field]: value,
          lastSaved: new Date().toISOString(),
        };
        if (sessionId) {
          updateOnboardingResponses(sessionId, updateData);
        }
        set((state) => ({
          formData: {
            ...state.formData,
            ...updateData,
          },
        }));
      },
      setOnboardingField: (field, value, sessionId) => {
        const updateData = {
          onboardingResponses: { [field]: value },
          lastSaved: new Date().toISOString(),
        };
        if (sessionId) {
          updateOnboardingResponses(sessionId, updateData);
        }
        set((state) => ({
          formData: {
            ...state.formData,
            onboardingResponses: {
              ...state.formData.onboardingResponses,
              [field]: value,
            },
            lastSaved: new Date().toISOString(),
          },
        }));
      },
      nextStep: () =>
        set((state) => ({
          formData: {
            ...state.formData,
            currentStep: state.formData.currentStep + 1,
          },
        })),
      prevStep: () =>
        set((state) => ({
          formData: {
            ...state.formData,
            currentStep: Math.max(0, state.formData.currentStep - 1),
          },
        })),
      resetForm: () => set({ formData: initialState }),
    }),
    {
      name: "form-storage",
    },
  ),
);
