import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./pages/landing";
import ProfilePage from "./pages/profile";
import FitFinder from "./pages/fit-finder";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MatchesPage from "./pages/matches";
import About from "./pages/about";
import Membership from "./pages/membership";
import IntroMembership from "./pages/membership/intro-membership";
import Account from "./pages/account";
import Introductions from "./pages/account/Introductions";
import ClientProfilePage from "./pages/account/ClientProfile";
import ClientMatches from "./pages/account/ClientMatches";
import AccountSettings from "./pages/account/AccountSettings";
import SubscriptionConfirmation from "./pages/account/SubscriptionConfirmation";
import IntroductionsNew from "./pages/account/IntroductionsNew";
import Match from "./pages/match";
import ClientProfilePageNew from "./pages/account/ClientProfileNew";
import Credits from "./pages/credits";
import IntroductionRequestPage from "./pages/introduction-request";
import IntroductionSuccessPage from "./pages/introduction-request/intro-success";
import Onboarding from "./pages/onboarding";
import IntroductionRequestPageNoPhoto from "./pages/introduction-request/index-nophoto";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Create a client
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/about" element={<About />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/fit-finder" element={<FitFinder />} />
          <Route path="/profiles" element={<MatchesPage />} />
          <Route path="/match" element={<Match />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/intro-membership" element={<IntroMembership />} />
          <Route path="/account" element={<Account />} />
          <Route
            path="/account/client-profile"
            element={<ClientProfilePage />}
          />
          <Route
            path="/account/client-profile-new"
            element={<ClientProfilePageNew />}
          />
          <Route path="/account/matches" element={<ClientMatches />} />
          <Route path="/account/introductions" element={<Introductions />} />
          <Route path="/introductions" element={<IntroductionsNew />} />
          <Route path="/account/settings" element={<AccountSettings />} />
          <Route path="/credits" element={<Credits />} />
          <Route
            path="/account/subscription-confirmation"
            element={<SubscriptionConfirmation />}
          />
          <Route
            path="/introduction-request"
            element={<IntroductionRequestPage />}
          />
          <Route
            path="/introduction-request-nophoto"
            element={<IntroductionRequestPageNoPhoto />}
          />
          <Route
            path="/introduction-success"
            element={<IntroductionSuccessPage />}
          />
          <Route path="/onboarding" element={<Onboarding />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
