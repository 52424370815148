import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { Button } from "../../../components/ui/button";
import { useFormStore } from "../../../store/formStore";
import { ArrowRight, ChevronLeft } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { updateOnboardingResponses } from "../../../services/userService";

export const ValuesInterstitial = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");
  const { formData, nextStep, prevStep } = useFormStore();

  useEffect(() => {
    window.scrollTo(0, 0);
    // Add viewport meta tag to prevent unwanted mobile zooming
    const viewportMeta = document.createElement("meta");
    viewportMeta.name = "viewport";
    viewportMeta.content =
      "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
    document.head.appendChild(viewportMeta);

    return () => {
      // Clean up by removing the meta tag when component unmounts
      document.head.removeChild(viewportMeta);
    };
  }, []);

  const handleNext = () => {
    if (sessionId) {
      updateOnboardingResponses(sessionId, {
        currentStep: formData.currentStep + 1,
        lastSaved: new Date().toISOString(),
      });
    }
    nextStep();
  };

  const handleBack = () => {
    if (sessionId) {
      updateOnboardingResponses(sessionId, {
        currentStep: formData.currentStep - 1,
        lastSaved: new Date().toISOString(),
      });
    }
    prevStep();
  };

  return (
    <div className="fixed inset-0 w-full h-full flex flex-col bg-white overflow-hidden touch-none">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.8, duration: 0.8 }}
        className="absolute top-0 left-0 z-50 p-4"
      >
        <button
          onClick={handleBack}
          className="flex items-center gap-2 text-muted-foreground hover:text-primary transition-colors duration-300"
        >
          <ChevronLeft className="w-3.5 h-3.5" />
          <span className="text-sm">Back</span>
        </button>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="flex-grow flex flex-col items-center justify-center px-6 sm:px-8 py-12 text-center relative z-10"
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.6 }}
          className="space-y-8 sm:space-y-10 max-w-lg mx-auto"
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.6, duration: 0.5 }}
            className="space-y-4"
          >
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#6E59A5] to-[#9b87f5] leading-relaxed">
              Thanks {formData.name}, we're excited to have you join Curate!
            </h1>
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8, duration: 0.5 }}
              className="w-24 h-1 bg-gradient-to-r from-[#6E59A5] to-[#9b87f5] mx-auto rounded-full"
            />
          </motion.div>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 0.5 }}
            className="text-base sm:text-lg text-gray-600 leading-relaxed max-w-md mx-auto"
          >
            Get ready for our carefully crafted questionnaire designed with
            relationship experts. Your answers will help us find matches that
            truly align with your values and lifestyle.
          </motion.p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1.2, duration: 0.6 }}
          className="w-full max-w-md mx-auto mt-12 sm:mt-16"
        >
          <Button
            onClick={handleNext}
            className="w-full bg-gradient-to-r from-[#8B5CF6] to-[#7E69AB] hover:opacity-90 transition-all duration-300 text-white rounded-full py-7 shadow-[0_8px_24px_-8px_rgba(139,92,246,0.5)] hover:shadow-[0_12px_28px_-8px_rgba(139,92,246,0.6)] text-lg font-medium"
          >
            Start Questionnaire
            <motion.div
              initial={{ x: -5 }}
              animate={{ x: 0 }}
              transition={{
                repeat: Infinity,
                repeatType: "mirror",
                duration: 1,
                repeatDelay: 1,
              }}
            >
              <ArrowRight className="ml-2 h-5 w-5" />
            </motion.div>
          </Button>
        </motion.div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 1 }}
        className="fixed inset-0 w-full h-full bg-gradient-to-b from-[#F5F3FF]/80 via-[#E5DEFF]/40 to-white pointer-events-none"
      />

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        transition={{ delay: 0.4, duration: 1 }}
        className="fixed inset-0 w-full h-full bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-[#6E59A5] to-transparent pointer-events-none"
      />
    </div>
  );
};
