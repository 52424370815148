import React from 'react';
import Div100vh from 'react-div-100vh';

const IntroductionSuccessPage: React.FC = () => {
  return (
    <Div100vh className="bg-[#F9F6F4] flex flex-col items-center justify-center px-4 text-center">
      <div className="max-w-md mx-auto">
        <h1 className="text-2xl font-light mb-4 font-['Montserrat']">
          Wonderful - A matchmaker will start a group message so the two of you can connect!
        </h1>
        
        <div className="mt-12">
          <h2 className="text-xl font-semibold mb-4 font-serif">
            Want more matches?
          </h2>
          <button
            onClick={() => window.location.href = 'https://apply.curate.date/'}
            className="w-full bg-white border border-black text-black font-light rounded-lg h-12 font-['Montserrat'] shadow-[0_4px_12px_rgba(0,0,0,0.1)] hover:shadow-[0_6px_16px_rgba(0,0,0,0.15)] hover:bg-black hover:text-white transition-all duration-300"
          >
            Apply to Curate
          </button>
        </div>
      </div>
    </Div100vh>
  );
};

export default IntroductionSuccessPage;