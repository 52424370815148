import { useEffect, useState } from "react";
import {
  getClientBio,
  updateClientBio,
  updateClientProfilePhoto,
  updateClientProfile,
  addClientProfilePhoto,
} from "../services/userService";
import { useSearchParams } from "react-router-dom";

const useUser = () => {
  const [userBio, setUserBio] = useState<string | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const [profilePhotoUrl, setProfilePhotoUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchClientBio = async () => {
      const sessionId = searchParams.get("sessionId");
      if (!sessionId) {
        throw new Error("No session ID available");
      }
      const clientBio = await getClientBio(sessionId);

      setUserBio(clientBio.bio);
      setUserName(clientBio.name);
      setProfilePhotoUrl(clientBio.profile_photo_url);
    };
    fetchClientBio();
  }, []);

  const updateBio = async (sessionId: string, newBio: string) => {
    setUserBio(newBio);
    const updatedBio = await updateClientBio(sessionId, newBio);
  };

  const updateProfilePhoto = async (sessionId: string, file: File) => {
    await updateClientProfilePhoto(sessionId, file);
    window.location.reload();
  };

  const addProfilePhoto = async (sessionId: string, base64String: string) => {
    const updatedProfilePhoto = await addClientProfilePhoto(sessionId, base64String);
    return updatedProfilePhoto;
    window.location.reload();
  };

  const updateProfile = async (sessionId: string, newInfo: Record<string, any>) => {
    const updatedProfile = await updateClientProfile(sessionId, newInfo);
  };

  return { userBio, userName, updateBio, updateProfilePhoto, profilePhotoUrl, addProfilePhoto, updateProfile };
};

export default useUser;
