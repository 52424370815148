import { FormLayout } from "./components/FormLayout";
import { PhoneInput } from "./components/PhoneInput";
import { PhoneVerification } from "./components/PhoneVerification";
import { BasicInformation } from "./components/BasicInformation";
import { LocationInformation } from "./components/LocationInformation";
import { ProfessionalInformation } from "./components/ProfessionalInformation";
import { PersonalPreferences } from "./components/PersonalPreferences";
import { ValuesInterstitial } from "./components/ValuesInterstitial";
import { RelationshipValues } from "./components/RelationshipValues";
import { FinalSteps } from "./components/FinalSteps";
import { FitFinderIntro } from "./components/FitFinderIntro";
import { SuccessPage } from "./components/SuccessPage";
import { FitFinder } from "./components/FitFinder";
import { useFormStore } from "../../store/formStore";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getOnboardingResponses } from "../../services/userService";

const Onboarding = () => {
  const { formData, setFormData, resetForm } = useFormStore();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");
  const [isLoading, setIsLoading] = useState(!!sessionId);

  // Effect to fetch and set onboarding responses
  useEffect(() => {
    const fetchOnboardingResponses = async () => {
      if (sessionId) {
        try {
          const responses = await getOnboardingResponses(sessionId);
          if (!responses || Object.keys(responses).length === 0) {
            resetForm();
          } else {
            setFormData({
              ...responses,
              birthdate: responses.birthdate?.split("T")[0],
            });
          }
        } catch (error) {
          console.error("Error fetching onboarding responses:", error);
          resetForm();
        } finally {
          setIsLoading(false);
        }
      } else {
        // If no session ID, reset to initial state
        resetForm();
        setIsLoading(false);
      }
    };

    fetchOnboardingResponses();
  }, [sessionId, setFormData, resetForm]);

  // Effect to scroll to top whenever the step changes
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant" as ScrollBehavior, // Use instant instead of smooth to prevent visual jumps
    });
  }, [formData.currentStep]);

  const renderStep = () => {
    switch (formData.currentStep) {
      case 0:
        return (
          <div className="h-[calc(100vh-2rem)] flex flex-col justify-center">
            <div className="space-y-8">
              <div className="text-center">
                <h1 className="text-2xl sm:text-3xl font-semibold text-[#2C2C2C]">
                  Welcome to Curate
                </h1>
              </div>
              <PhoneInput />
            </div>
          </div>
        );
      case 1:
        return <PhoneVerification />;
      case 2:
        return <BasicInformation />;
      case 3:
        return <LocationInformation />;
      case 4:
        return <ProfessionalInformation />;
      case 5:
        return <PersonalPreferences />;
      case 6:
        return <ValuesInterstitial />;
      case 7:
        return <RelationshipValues />;
      case 8:
        return <FinalSteps />;
      case 9:
        return <FitFinderIntro />;
      case 10:
        return <FitFinder />;
      case 11:
        return <SuccessPage />;
      default:
        return <div>Next steps coming soon...</div>;
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <FormLayout showProgress={formData.currentStep < 11}>
      {renderStep()}
    </FormLayout>
  );
};

export default Onboarding;
