export const HEIGHTS = Array.from({ length: 48 }, (_, i) => {
  const feet = Math.floor((i + 48) / 12);
  const inches = (i + 48) % 12;
  return {
    value: `${feet}'${inches}"`,
    label: `${feet}'${inches}"`,
  };
});

export const MIN_AGE = 18;
