import React from "react";
import { motion } from "framer-motion";
import { Button } from "../../../components/ui/button";
import { useFormStore } from "../../../store/formStore";
import { ArrowLeft, Heart, Sparkles, ArrowRight } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { updateOnboardingResponses } from "../../../services/userService";

export const FitFinderIntro = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");
  const { nextStep, prevStep, formData } = useFormStore();

  const handleNext = () => {
    if (sessionId) {
      // window.open(`/fit-finder?sessionId=${sessionId}`, '_blank');
      updateOnboardingResponses(sessionId, {
        currentStep: formData.currentStep + 1,
        lastSaved: new Date().toISOString(),
      });
    }
    nextStep();
  };

  const handleBack = () => {
    if (sessionId) {
      updateOnboardingResponses(sessionId, {
        currentStep: formData.currentStep - 1,
        lastSaved: new Date().toISOString(),
      });
    }
    prevStep();
  };

  return (
    <div className="space-y-2">
      <Button
        variant="outline"
        onClick={handleBack}
        className="flex p-2 items-center gap-1.5 text-muted-foreground/40 hover:text-muted-foreground/70 -ml-3 text-xs font-normal"
      >
        <ArrowLeft className="w-2.5 h-2.5" />
        Back
      </Button>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center space-y-4 sm:space-y-6"
      >
        <div className="space-y-2 sm:space-y-3">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            className="inline-flex p-2 sm:p-3 rounded-full bg-[#8B5CF6]/10"
          >
            <Heart className="w-10 h-10 sm:w-12 sm:h-12 text-[#8B5CF6]" />
          </motion.div>

          <h2 className="text-2xl sm:text-3xl font-semibold text-primary">
            Let's Find Your Perfect Match
          </h2>

          <p className="text-lg text-muted-foreground max-w-md mx-auto">
            Help us understand your type by playing our interactive Fit Finder
            game
          </p>
        </div>

        <div className="space-y-3 bg-primary/5 p-4 sm:p-5 rounded-2xl">
          <div className="flex items-center justify-center gap-2 text-primary">
            <Sparkles className="w-5 h-5" />
            <p className="font-medium">How it works</p>
          </div>

          <div className="space-y-2 sm:space-y-3 text-sm text-muted-foreground">
            <p>We'll show you pairs of photos</p>
            <p>Simply tap the one you find more attractive</p>
            <p>This helps us understand your preferences</p>
          </div>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <Button
            onClick={handleNext}
            className="w-full sm:w-auto px-8 py-6 text-lg bg-[#8B5CF6] hover:bg-[#7C4DEF] text-white transition-all duration-300 shadow-lg hover:shadow-xl rounded-xl group"
          >
            <span className="flex items-center justify-center gap-2">
              Start Fit Finder
              <motion.div
                initial={{ x: 0 }}
                animate={{ x: [0, 4, 0] }}
                transition={{
                  repeat: Infinity,
                  duration: 1.5,
                  ease: "easeInOut",
                }}
              >
                <ArrowRight className="w-5 h-5" />
              </motion.div>
            </span>
          </Button>
        </motion.div>
      </motion.div>
    </div>
  );
};
