import React, { useState, useEffect } from "react";
import { Button } from "../../../components/ui/button";
import { useFormStore } from "../../../store/formStore";
import { MessageCircle, Loader2 } from "lucide-react";
import { motion } from "framer-motion";
import { useQuery } from "@tanstack/react-query";
import {
  getPhoneVerification,
  getOnboardingResponses,
} from "../../../services/userService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatPhoneNumber } from "../../../utils/textFormatters";

export const PhoneVerification = () => {
  const { formData, nextStep, prevStep, setField, setFormData } =
    useFormStore();
  const [isVerifying, setIsVerifying] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const phoneNumber = searchParams.get("phone");

  const { data } = useQuery({
    queryKey: ["phoneVerification"],
    queryFn: () => getPhoneVerification(phoneNumber || ""),
    refetchInterval: 5000,
  });

  useEffect(() => {
    const verifyAndContinue = async () => {
      if (data && data.is_verified === true) {
        setIsVerifying(false);
        setIsVerified(true);

        // Update URL params from phone to sessionId
        navigate(`?sessionId=${data.session_id}`, { replace: true });

        // Fetch and update onboarding responses with new session ID
        try {
          setField("isVerified", true);
          const responses = await getOnboardingResponses(data.session_id);
          if (responses && Object.keys(responses).length > 0) {
            setFormData({
              ...responses,
              birthdate: responses.birthdate?.split("T")[0],
            });
          }
        } catch (error) {
          console.error("Error fetching onboarding responses:", error);
        }
      }
    };

    verifyAndContinue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleContinue = () => {
    if (!isVerified) return;
    setField("isVerified", true);
    nextStep();
  };

  return (
    <div className="space-y-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center space-y-8"
      >
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          className="inline-flex p-4 rounded-full bg-[#222222]/10 text-[#222222]"
        >
          <MessageCircle className="w-12 h-12" />
        </motion.div>

        <div className="space-y-6">
          <motion.h2
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-2xl sm:text-3xl font-semibold bg-gradient-to-r from-[#222222] to-[#555555] text-transparent bg-clip-text"
          >
            Please Verify Your Number
          </motion.h2>

          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="space-y-6"
          >
            <div className="space-y-2">
              <p className="text-lg text-[#555555]">
                We just sent a text message to
              </p>
              <p className="text-2xl font-semibold bg-gradient-to-r from-[#222222] to-[#403E43] text-transparent bg-clip-text">
                {formatPhoneNumber(
                  phoneNumber ? phoneNumber : formData.phoneNumber,
                )}
              </p>
            </div>

            <div className="bg-[#f3f3f3] p-6 rounded-2xl max-w-sm mx-auto border border-[#eee]">
              <p className="text-lg font-medium text-[#222222]">
                Reply back with your name to get started
              </p>
            </div>
          </motion.div>
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.4 }}
      >
        <Button
          onClick={handleContinue}
          disabled={isVerifying || !isVerified}
          className="w-full bg-[#222222] text-white hover:bg-[#333333] transition-colors py-6 text-lg rounded-xl shadow-sm disabled:opacity-70"
        >
          {isVerifying ? (
            <>
              <Loader2 className="mr-2 h-5 w-5 animate-spin" />
              Waiting for reply...
            </>
          ) : isVerified ? (
            "Phone Verified"
          ) : (
            "Waiting for verification..."
          )}
        </Button>
      </motion.div>
    </div>
  );
};
