import React, { FC, useState, useEffect, useCallback } from "react";
import { useMatches } from "../../hooks/useMatches";
// import { usePostHog } from "posthog-js/react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Match } from "../../core/models";
import { Header } from "../../components/Header";

// Enhanced loading skeleton with more realistic representation
const LoadingSkeleton: FC = () => (
  <div className="min-h-screen bg-[#FAF9F7]">
    <div className="h-16 bg-white shadow-sm animate-pulse" />{" "}
    {/* Header skeleton */}
    <main className="mx-auto px-4 py-8 bg-[#F0EEE9]">
      <div className="max-w-2xl bg-white rounded-lg shadow-lg mx-auto md:px-8 pt-4 pb-[6.5rem]">
        <div className="bg-white rounded-lg shadow-sm px-8 py-4 relative">
          <div className="h-8 bg-gray-200 rounded w-48 mx-auto mb-4 animate-pulse" />
          <div className="max-w-xl mx-auto">
            <div className="aspect-[3/4] bg-gray-200 mb-6 rounded-lg overflow-hidden max-w-[200px] md:max-w-[280px] mx-auto animate-pulse" />
            <div className="text-center space-y-4">
              <div className="h-6 bg-gray-200 rounded w-48 mx-auto animate-pulse" />
              <div className="h-6 bg-gray-200 rounded w-full animate-pulse" />
              <div className="space-y-2">
                <div className="h-4 bg-gray-200 rounded w-full animate-pulse" />
                <div className="h-4 bg-gray-200 rounded w-5/6 animate-pulse" />
                <div className="h-4 bg-gray-200 rounded w-4/6 animate-pulse" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
);

// Optimized image component
const MatchImage = React.memo(({ match }: { match: Match }) => {
  const [isLoading, setIsLoading] = useState(true);
  const imageUrl = `data:image/jpeg;base64,${match.prospect_image}`;

  return (
    <div className="aspect-[3/4] bg-gray-200 mb-6 rounded-lg overflow-hidden max-w-[200px] md:max-w-[280px] mx-auto shadow-md relative">
      {isLoading && (
        <div className="absolute inset-0 bg-gray-200 animate-pulse" />
      )}
      <img
        src={imageUrl}
        alt={match.name}
        className={`w-full h-full object-cover transition-opacity duration-300 ${
          isLoading ? "opacity-0" : "opacity-100"
        }`}
        loading="lazy"
        onLoad={() => setIsLoading(false)}
        onError={(e) => {
          setIsLoading(false);
          e.currentTarget.src = "/fallback-image.jpg";
        }}
      />
    </div>
  );
});

// Add this new component at the top of the file, after imports
const PhoneModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}> = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-40">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div className="fixed bottom-[120px] left-0 right-0 z-50 flex flex-col items-center">
        <div className="w-[90%] bg-white rounded-lg shadow-lg mx-auto">
          <div className="px-6 pt-4">
            <h3 className="text-md font-medium">{title}</h3>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

// Add this new component after the PhoneModal component
const SuccessModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div className="fixed inset-0 flex items-center justify-center p-8">
        <div className="bg-[#F9F6F4] w-full max-w-md rounded-lg p-10 text-center">
          <h1 className="text-2xl font-light mb-6 font-['Montserrat']">
            Wonderful - A matchmaker will start a group message so the two of
            you can connect!
          </h1>

          <div className="mt-16">
            <h2 className="text-xl font-semibold mb-6 font-serif">
              Want more matches?
            </h2>
            <button
              onClick={() =>
                (window.location.href = "https://apply.curate.date/")
              }
              className="w-full bg-white border border-black text-black font-light rounded-lg h-12 font-['Montserrat'] shadow-[0_4px_12px_rgba(0,0,0,0.1)] hover:shadow-[0_6px_16px_rgba(0,0,0,0.15)] hover:bg-black hover:text-white transition-all duration-300"
            >
              Apply to Curate
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Add this new component after the SuccessModal component
const SimpleSuccessModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div className="fixed inset-0 flex items-center justify-center p-8">
        <div className="bg-[#F9F6F4] w-full max-w-md rounded-lg p-10 text-center">
          <h1 className="text-2xl font-light mb-6 font-['Montserrat']">
            Wonderful - A matchmaker will start a group message so the two of
            you can connect!
          </h1>
        </div>
      </div>
    </div>
  );
};

export interface TallyImage {
  id: string;
  name: string;
  url: string;
}

const IntroductionRequestPage: FC = () => {
  // const posthog = usePostHog();
  const [searchParams] = useSearchParams();
  const matchId = searchParams.get("matchId");
  const navigate = useNavigate();
  const [isBottomBarHidden, setIsBottomBarHidden] = useState(false);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isSimpleSuccessModalOpen, setIsSimpleSuccessModalOpen] =
    useState(false);
  const [introInfo, setIntroInfo] = useState<{
    exists: boolean;
    user_photo: TallyImage | string;
    user_age: number;
    user_bio: string;
    user_location: string;
    user_name: string;
    why_this_match: Array<string>;
    id: string;
  } | null>(null);

  const {
    isLoading,
    sessionId,
    getIntroductionInfo,
    patchIntroduction,
    getIntroduced,
  } = useMatches();

  // Memoize handlers
  const toggleBottomBar = useCallback(() => {
    setIsBottomBarHidden((prev) => !prev);
  }, []);

  const handlePhoneNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.replace(/\D/g, "").slice(0, 10);
      setPhoneNumber(value);
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    if (matchId) {
      await getIntroduced(matchId, phoneNumber);
    }
    setIsPhoneModalOpen(false);
    if (isSubscribed) {
      setIsSimpleSuccessModalOpen(true);
    } else {
      setIsSuccessModalOpen(true);
    }
  }, [isSubscribed, matchId, phoneNumber, getIntroduced]);

  useEffect(() => {
    const updateIntroStatus = async () => {
      if (introInfo?.id) {
        await patchIntroduction({
          id: introInfo.id,
          status: "in progress",
        });
      }
    };

    updateIntroStatus();
  }, [introInfo?.id]);

  // Load font only once on mount
  useEffect(() => {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "https://use.typekit.net/ild3mkn.css";
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  useEffect(() => {
    const fetchIntroInfo = async () => {
      if (matchId) {
        try {
          const info = await getIntroductionInfo(matchId);
          console.log("Fetched introInfo:", info);
          setIntroInfo(info);
          if (info.prospect_phone_number) {
            setPhoneNumber(info.prospect_phone_number);
          }
        } catch (error) {
          console.error("Error fetching introduction info:", error);
        }
      }
    };

    fetchIntroInfo();
  }, [matchId, getIntroductionInfo]);

  if (isLoading) return <LoadingSkeleton />;

  return (
    <div className="min-h-screen bg-[#FAF9F7]">
      <Header sessionId={sessionId} />

      <main className="mx-auto px-4 py-12 bg-[#F0EEE9]">
        <div className="max-w-2xl bg-white rounded-xl shadow-md mx-auto md:px-8 pt-6 pb-[1.5rem] md:pb-[1.5rem] relative">
          <div className="bg-white rounded-xl shadow-none px-8 py-6 relative">
            <h2 className="md:text-3xl text-2xl font-light text-center mb-2 acumin-regular tracking-wide">
              Meet {introInfo?.user_name}
            </h2>
            <div className="max-w-xl mx-auto">
              {introInfo?.user_photo ? (
                <div className="aspect-[3/4] bg-gray-200 mb-6 rounded-lg overflow-hidden max-w-[200px] md:max-w-[280px] mx-auto shadow-md">
                  {typeof introInfo?.user_photo === "string" ? (
                    <img
                      src={`data:image/png;base64,${introInfo?.user_photo}`}
                      alt="User"
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <img
                      src={introInfo?.user_photo.url}
                      alt={introInfo?.user_photo.name}
                      className="w-full h-full object-cover"
                    />
                  )}
                </div>
              ) : null}

              <div className="text-center">
                <p className="text-lg font-light text-gray-600 mb-3 acumin-regular">
                  {introInfo?.user_location} | {introInfo?.user_age} years old
                </p>
                <h4 className="text-lg md:text-xl font-semibold mb-2 text-left acumin-regular">
                  {introInfo?.user_name.split(" ")[0]}'s story
                </h4>
                <p className="text-base font-medium text-gray-600 mb-8 font-light text-left acumin-regular leading-relaxed">
                  {introInfo?.user_bio}
                </p>
              </div>

              <div>
                <h4 className="text-lg md:text-xl mb-2.5 font-semibold acumin-regular tracking-tight">
                  Why we selected this match
                </h4>
                <div className="text-base font-medium md:text-md text-gray-600 mb-8 font-light acumin-regular">
                  <ul className="list-none space-y-2 md:space-y-3">
                    {(introInfo?.why_this_match
                      ? Array.isArray(introInfo.why_this_match)
                        ? introInfo.why_this_match
                        : []
                      : []
                    )
                      .filter(
                        (point): point is string => typeof point === "string",
                      )
                      .map((point, index) => (
                        <li
                          key={index}
                          className="flex items-start py-1 md:py-1.5 hover:bg-gray-50 rounded-lg px-2 transition-colors duration-200"
                        >
                          <span className="text-gray-400 mr-3 text-lg">•</span>
                          <span className="leading-relaxed tracking-wide text-gray-700">
                            {point.replace("• ", "").trim()}
                          </span>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div
          onClick={toggleBottomBar}
          className={`fixed bottom-0 left-0 right-0 px-4 pb-8 pt-8 transition-all duration-300 cursor-pointer z-50
              ${
                isBottomBarHidden
                  ? 'translate-y-full before:content-[""] before:fixed before:bottom-0 before:left-0 before:right-0 before:h-[50vh] before:bg-transparent before:cursor-pointer before:z-10'
                  : "translate-y-0"
              } bg-gradient-to-t from-[#FAF9F7] via-[#FAF9F7]/90 to-transparent backdrop-blur-sm`}
        >
          <div className="max-w-xl mx-auto space-y-3">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsPhoneModalOpen(true);
              }}
              className="w-full h-14 bg-black text-white font-medium rounded-lg hover:bg-gray-900 luxury-button luxury-button-primary"
              style={{ fontFamily: "Montserrat" }}
            >
              Get Introduced
            </button>
          </div>
        </div>
      </main>

      {isPhoneModalOpen && (
        <>
          <PhoneModal
            isOpen={isPhoneModalOpen}
            onClose={() => setIsPhoneModalOpen(false)}
            title="Enter your phone number"
          >
            <div className="py-4 px-6 bg-white rounded-xl">
              <input
                type="tel"
                placeholder="(555) 123-4567"
                className="w-full p-3 border-b-2 border-gray-200 mb-6 focus:outline-none focus:border-gray-800 transition-colors duration-200"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
              <label className="flex items-center gap-2 mb-4">
                <input
                  type="checkbox"
                  className="rounded accent-black"
                  checked={isSubscribed}
                  onChange={(e) => setIsSubscribed(e.target.checked)}
                />
                <span className="text-xs">
                  Sign me up to receive even more Curate matches!
                </span>
              </label>
            </div>
          </PhoneModal>
          <div className="fixed bottom-0 left-0 right-0 z-50 px-4 pb-6 bg-gradient-to-t from-[#F9F6F4] via-[#F9F6F4]/80 to-transparent">
            <div className="max-w-xl mx-auto">
              <button
                onClick={handleSubmit}
                className="w-full h-14 font-medium rounded-lg luxury-button luxury-button-primary bg-black text-white hover:bg-gray-900"
                style={{ fontFamily: "Montserrat" }}
              >
                Submit
              </button>
            </div>
          </div>
        </>
      )}

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
      />
      <SimpleSuccessModal
        isOpen={isSimpleSuccessModalOpen}
        onClose={() => setIsSimpleSuccessModalOpen(false)}
      />
    </div>
  );
};

// Remove the React.memo wrapper and export the component directly with the provider
export default React.memo(IntroductionRequestPage);
