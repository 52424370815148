import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  User,
  CreditCard,
  Heart,
  ChevronLeft,
  Menu,
  ChevronDown,
} from "lucide-react";
import { FC, useEffect, useState } from "react";
import { Popover } from "./ui/popover";
import Logo from "./Logo";

type IProps = {
  sessionId?: string | null;
};

const MenuLinks: FC<IProps> = ({ sessionId }) => {
  const location = useLocation();
  const credits = null; // TODO: get credits from backend
  const pendingIntroductions = null; // TODO: get pending introductions from backend

  return (
    <>
      <Link
        to={`/account/client-profile-new${
          sessionId ? `?sessionId=${sessionId}` : ""
        }`}
        className={`relative transition-colors inline-flex items-center hover:bg-slate-50 w-full py-3 px-4 lg:px-3 rounded-t-lg lg:rounded-lg ${
          location.pathname === "/account/client-profile-new"
            ? "text-black bg-slate-50"
            : "text-black/70 hover:text-black"
        }`}
        aria-label="Client Profile"
      >
        <User className="w-5 h-5 lg:w-6 lg:h-6" />
        <span className="ml-2 inline-block lg:hidden">Your profile</span>
      </Link>
      <Link
        to={`/introductions${sessionId ? `?sessionId=${sessionId}` : ""}`}
        className={`relative transition-colors inline-flex items-center hover:bg-slate-50 w-full py-3 px-4 lg:px-3 lg:rounded-lg ${
          location.pathname === "/introductions"
            ? "text-black bg-slate-50"
            : "text-black/70 hover:text-black"
        }`}
        aria-label="Introductions"
      >
        <Heart className="w-5 h-5 lg:w-6 lg:h-6" />
        <span className="ml-2 inline-block lg:hidden">Introductions </span>
        {pendingIntroductions && <span>({pendingIntroductions})</span>}
      </Link>
      <Link
        to={`/credits${sessionId ? `?sessionId=${sessionId}` : ""}`}
        className={`relative transition-colors inline-flex items-center hover:bg-slate-50 w-full py-3 px-4 rounded-b-lg lg:px-3 lg:rounded-lg ${
          location.pathname === "/credits"
            ? "text-black bg-slate-50"
            : "text-black/70 hover:text-black"
        }`}
        aria-label="Credits"
      >
        <CreditCard className="w-5 h-5 lg:w-6 lg:h-6" />
        <span className="ml-2 inline-block lg:hidden">Credits</span>
        {credits && <span>({credits})</span>}
      </Link>
    </>
  );
};

export const Header: FC<IProps> = ({ sessionId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [canGoBack, setCanGoBack] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const isHomePage = location.pathname === "/";

  useEffect(() => {
    // Check if we can go back in history
    setCanGoBack(window.history.length > 1);
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <header className="sticky top-0 z-50 w-full border-b border-taupe/30 bg-white/95 backdrop-blur supports-[backdrop-filter]:bg-white/90 flex justify-center items-center ">
      <div className="container max-w-screen-2xl flex h-20 items-center justify-between px-4">
        <div className="flex-1">
          {!isHomePage && canGoBack && sessionId && (
            <button
              onClick={handleBack}
              className="text-taupe hover:text-black transition-colors"
            >
              <ChevronLeft className="h-6 w-6" />
              <span className="sr-only">Go Back</span>
            </button>
          )}
        </div>
        {sessionId ? (
          <Link
            to="https://www.curate.date/"
            className="relative transition-colors"
            rel="noopener noreferrer"
            aria-label="Client Profile"
          >
            <Logo />
          </Link>
        ) : (
          <a
            href="https://www.curate.date/"
            className="relative"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Logo />
          </a>
        )}
        <nav className="flex-1 flex items-center justify-end md:space-x-8 space-x-4 h-full">
          {sessionId ? (
            <>
              <div className="hidden lg:flex">
                <MenuLinks sessionId={sessionId} />
              </div>
              <div className="items-center justify-center flex lg:hidden">
                <Popover
                  trigger={
                    <div className="inline-flex items-center px-2 py-1.5 md:py-2 bg-black text-xs md:text-sm font-medium text-white rounded-lg transition-all duration-500 hover:shadow-lg md:mt-0 focus:ring-2">
                      <Menu size={20} />
                      <ChevronDown size={14} className="ml-1" />
                    </div>
                  }
                >
                  <div className="text-sm text-gray-700">
                    <MenuLinks sessionId={sessionId} />
                  </div>
                </Popover>
              </div>
            </>
          ) : (
            <>
              <Link
                to="/onboarding"
                className={`inline-flex items-center px-2 py-1.5 md:px-4 md:py-2 bg-black text-xs md:text-sm font-medium text-white rounded-lg transition-all duration-500 font-montserrat
                hover:translate-y-[-2px] hover:shadow-lg
                animate-float
                md:mt-0
                ${
                  isScrolled
                    ? "transform scale-105 shadow-md bg-black/95"
                    : "hover:bg-black/90"
                }
              `}
              >
                <span>Join Curate</span>
              </Link>
            </>
          )}
        </nav>
      </div>
    </header>
  );
};
