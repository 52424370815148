import React, { useEffect, useRef } from "react";
import { Button } from "../../../components/ui/button";
import { useFormStore } from "../../../store/formStore";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { updateOnboardingResponses } from "../../../services/userService";

type PreferenceCategory = {
  id: string;
  title: string;
  questions: Array<{
    question: string;
    options: string[];
    allowMultiple?: boolean;
  }>;
};

const preferenceCategories: PreferenceCategory[] = [
  {
    id: "coreValues",
    title: "Core Relationship Values",
    questions: [
      {
        question: "In a relationship, I value:",
        options: ["Deep emotional intimacy", "Mutual respect and independence"],
      },
      {
        question: "For long-term relationship success, I believe:",
        options: [
          "Sharing similar values and goals is most important",
          "Having complementary personalities and shared humor is key",
          "Maintaining strong physical and emotional attraction is crucial",
        ],
      },
    ],
  },
  {
    id: "lifeApproach",
    title: "Life Approach & Future Outlook",
    questions: [
      {
        question: "When thinking about the future, I:",
        options: [
          "Have clear, specific goals and plans",
          "Prefer to go with the flow and see what happens",
        ],
      },
      {
        question: "I prefer to:",
        options: [
          "Live a life of adventure and risk-taking",
          "Live a life of safety and routine",
        ],
      },
      {
        question: "In my daily life, I prefer:",
        options: [
          "A structured routine with clear plans",
          "Flexibility and spontaneity",
        ],
      },
    ],
  },
  {
    id: "workBalance",
    title: "Work, Career & Family Balance",
    questions: [
      {
        question: "In terms of work-life balance, I prioritize:",
        options: [
          "Career advancement and financial success",
          "Personal time and family life",
        ],
      },
      {
        question: "I'd rather have a partner who is:",
        options: [
          "Financially successful but often busy and a workaholic",
          "Less financially successful but provides more emotional availability and time",
        ],
      },
      {
        question:
          "It's reasonable for one of us to take on more family responsibilities if the other is more focused on their career:",
        options: ["Agree", "Disagree"],
      },
    ],
  },
  {
    id: "familyPreferences",
    title: "Family & Parenting Preferences",
    questions: [
      {
        question: "Regarding having children:",
        options: [
          "Want children, and a larger family (3+ children)",
          "Want children, and a smaller family (1-2 children)",
          "Am unsure about kids - open to having them or not",
          "Prefer not to have children",
        ],
      },
      {
        question:
          "What values or traits do you hope to instill in your children?",
        options: [
          "Respect, discipline, and hard work",
          "Independence, confidence, and curiosity",
        ],
      },
    ],
  },
  {
    id: "culturalBeliefs",
    title: "Cultural & Religious Beliefs",
    questions: [
      {
        question: "When it comes to cultural or religious practices, I:",
        options: [
          "Have strong beliefs that I want to maintain",
          "Am flexible and open to different perspectives",
        ],
      },
      {
        question: "I prefer a partner who:",
        options: [
          "Values traditional religious practices",
          "Leans towards spirituality without specific religious affiliations",
          "Neither or not applicable",
        ],
      },
    ],
  },
  {
    id: "decisionMaking",
    title: "Decision-Making & Conflict Resolution",
    questions: [
      {
        question: "When it comes to making important decisions, I think:",
        options: [
          "We should always make decisions together",
          "It's okay for one partner to take the lead sometimes",
        ],
      },
      {
        question: "In a relationship conflict, I typically:",
        options: [
          "Deal with it openly and constructively",
          "Try to avoid the issue or distance myself",
        ],
      },
      {
        question:
          "When discussing important matters with my partner, I prefer to:",
        options: [
          "Express my thoughts and feelings openly",
          "Take time to process before responding",
        ],
      },
    ],
  },
  {
    id: "lifestyle",
    title: "Lifestyle & Social Preferences",
    questions: [
      {
        question: "In terms of socializing as a couple, I prefer:",
        options: [
          "Frequent outings and events with friends",
          "Quiet evenings at home, just the two of us",
        ],
      },
      {
        question: "In terms of where to live, I prefer:",
        options: [
          "A bustling city environment",
          "A quieter suburban or rural setting",
        ],
      },
      {
        question: "When around others, I tend to:",
        options: [
          "Adapt my behavior to fit what I think others expect or prefer",
          "Maintain consistent behavior regardless of the social context",
        ],
      },
    ],
  },
];

export const RelationshipValues = () => {
  const { formData, setOnboardingField, nextStep, prevStep } = useFormStore();
  const questionsRef = useRef<(HTMLDivElement | null)[]>([]);
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSelectOption = (
    categoryId: string,
    question: string,
    option: string,
  ) => {
    const currentValues =
      (formData.onboardingResponses[
        categoryId as keyof typeof formData.onboardingResponses
      ] as string[]) || [];
    const newValues = [...currentValues];

    const existingAnswerIndex = newValues.findIndex((value) =>
      value.startsWith(`${question}:`),
    );

    if (existingAnswerIndex !== -1) {
      newValues.splice(existingAnswerIndex, 1);
    }
    newValues.push(`${question}: ${option}`);

    setOnboardingField(
      categoryId as keyof typeof formData.onboardingResponses,
      newValues,
      sessionId ?? undefined,
    );

    const flatQuestions = preferenceCategories.flatMap((cat) => cat.questions);
    const currentIndex = flatQuestions.findIndex(
      (q) => q.question === question,
    );

    if (currentIndex < flatQuestions.length - 1) {
      requestAnimationFrame(() => {
        const nextRef = questionsRef.current[currentIndex + 1];
        if (nextRef) {
          nextRef.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const totalAnswered = Object.values(formData.onboardingResponses)
      .filter(Array.isArray)
      .reduce((sum, arr) => sum + arr.length, 0);

    const totalQuestions = preferenceCategories.reduce(
      (sum, category) => sum + category.questions.length,
      0,
    );

    if (totalAnswered < totalQuestions) {
      toast.error("Please answer all questions before proceeding");
      return;
    }

    if (sessionId) {
      updateOnboardingResponses(sessionId, {
        currentStep: formData.currentStep + 1,
        lastSaved: new Date().toISOString(),
      });
    }
    nextStep();
  };

  const handleBack = () => {
    if (sessionId) {
      updateOnboardingResponses(sessionId, {
        currentStep: formData.currentStep - 1,
        lastSaved: new Date().toISOString(),
      });
    }
    prevStep();
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-8 pb-4">
      {preferenceCategories.map((category, categoryIndex) => (
        <motion.div
          key={category.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: categoryIndex * 0.1 }}
          className="space-y-6 pb-6 border-b border-gray-100 last:border-0"
        >
          <motion.h3
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: categoryIndex * 0.1 + 0.2 }}
            className="text-lg font-semibold text-gray-900"
          >
            {category.title}
          </motion.h3>

          {category.questions.map((q, qIndex) => {
            const absoluteIndex =
              preferenceCategories
                .slice(0, categoryIndex)
                .reduce((sum, cat) => sum + cat.questions.length, 0) + qIndex;

            return (
              <motion.div
                key={qIndex}
                ref={(el) => {
                  questionsRef.current[absoluteIndex] = el;
                }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: qIndex * 0.1 }}
                className="space-y-3 bg-white/50 backdrop-blur-sm rounded-xl p-4 shadow-[0_2px_8px_-4px_rgba(0,0,0,0.05)] border border-gray-100"
              >
                <h4 className="text-base font-medium text-gray-800">
                  {q.question}
                </h4>
                <div className="space-y-2">
                  {q.options.map((option, optionIndex) => {
                    const isSelected = (
                      (formData.onboardingResponses[
                        category.id as keyof typeof formData.onboardingResponses
                      ] as string[]) || []
                    ).some((value) => value === `${q.question}: ${option}`);
                    const letter = String.fromCharCode(65 + optionIndex);

                    return (
                      <motion.button
                        key={option}
                        type="button"
                        whileTap={{ scale: 0.98 }}
                        onClick={() =>
                          handleSelectOption(category.id, q.question, option)
                        }
                        className={`w-full text-left px-4 py-3 rounded-lg transition-all duration-300 flex items-center gap-3 border-2 ${
                          isSelected
                            ? "border-[#2C2C2C] bg-white/80 shadow-[0_2px_12px_-4px_rgba(0,0,0,0.1)] hover:bg-gray-50"
                            : "border-gray-200 bg-white hover:bg-gray-50"
                        }`}
                      >
                        <div
                          className={`flex-shrink-0 w-7 h-7 rounded-full flex items-center justify-center text-sm font-medium transition-colors duration-300 ${
                            isSelected
                              ? "bg-[#2C2C2C] text-white"
                              : "bg-gray-50 text-gray-500"
                          }`}
                        >
                          {letter}
                        </div>
                        <span
                          className={`text-sm ${
                            isSelected
                              ? "text-gray-900 font-medium"
                              : "text-gray-700"
                          }`}
                        >
                          {option}
                        </span>
                      </motion.button>
                    );
                  })}
                </div>
              </motion.div>
            );
          })}
        </motion.div>
      ))}

      <div className="flex justify-between gap-4 mt-8 sticky bottom-0 bg-white/80 backdrop-blur-md p-4 rounded-t-xl shadow-[0_-4px_20px_-4px_rgba(0,0,0,0.1)]">
        <Button
          type="button"
          variant="outline"
          onClick={handleBack}
          className="w-24 p-2 border border-muted-foreground hover:bg-gray-50"
        >
          Back
        </Button>
        <Button
          type="submit"
          className="w-24 p-2 border border-[var(--primary)] bg-[var(--primary)] text-[var(--primary-foreground)] shadow-sm hover:shadow-md transition-all duration-300"
        >
          Next
        </Button>
      </div>
    </form>
  );
};
