import * as React from "react";
import { cn } from "../../lib/utils";

interface PopoverProps extends React.HTMLAttributes<HTMLDivElement> {
  trigger: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}

const Popover: React.FC<PopoverProps> = ({
  trigger,
  children,
  className,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const popoverRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative inline-block">
      <button onClick={() => setOpen((prev) => !prev)}>{trigger}</button>
      {open && (
        <div
          ref={popoverRef}
          className={cn(
            "absolute right-0 top-full mt-2 w-52 rounded-md border border-border bg-white shadow-lg transition-opacity duration-500",
            className,
          )}
          {...props}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export { Popover };
