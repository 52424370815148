import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { useFormStore } from "../../../store/formStore";
import { useSearchParams } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";
import { updateOnboardingResponses } from "../../../services/userService";

const REFERRAL_SOURCES = [
  "Outreach from Sara",
  "Outreach from Alice",
  "Instagram",
  "LinkedIn",
  "Flyer / Card / QR Code",
  "Family/Friend",
  "TikTok",
  "Influencer",
  "Google",
  "Press",
  "Podcast",
  "Billboard",
] as const;

export const FinalSteps = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");
  const { formData, setField, nextStep, prevStep } = useFormStore();

  const [referralSource, setReferralSource] = useState(formData.referralSource);
  const [referralCode, setReferralCode] = useState(formData.referralCode);

  const debouncedReferralCode = useDebounce(referralCode, 500);

  useEffect(() => {
    // Check URL parameters for referral information
    const params = new URLSearchParams(window.location.search);
    const source = params.get("source");
    const code = params.get("ref");

    if (source && !formData.referralSource) {
      setReferralSource(source);
      setField("referralSource", source, sessionId ?? undefined);
    }
    if (code && !formData.referralCode) {
      setReferralCode(code);
      setField("referralCode", code, sessionId ?? undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (debouncedReferralCode !== formData.referralCode) {
      setField("referralCode", debouncedReferralCode, sessionId ?? undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedReferralCode]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!referralSource) {
      return;
    }

    if (sessionId) {
      updateOnboardingResponses(sessionId, {
        currentStep: formData.currentStep + 1,
        lastSaved: new Date().toISOString(),
      });
    }
    nextStep();
  };

  const handleBack = () => {
    if (sessionId) {
      updateOnboardingResponses(sessionId, {
        currentStep: formData.currentStep - 1,
        lastSaved: new Date().toISOString(),
      });
    }
    prevStep();
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-6 flex flex-col min-h-[60vh] justify-between"
    >
      <div className="space-y-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="space-y-6"
        >
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3 }}
            className="space-y-2"
          >
            <h2 className="text-xl font-semibold text-primary">Final Steps</h2>
            <p className="text-sm text-gray-400">
              Help us understand how you discovered our community
            </p>
          </motion.div>

          <div className="space-y-4">
            <div className="space-y-2">
              <label
                htmlFor="referralSource"
                className="text-sm font-medium text-gray-700"
              >
                How did you hear about us? *
              </label>
              <select
                value={referralSource}
                onChange={(e) => {
                  setReferralSource(e.target.value);
                  setField(
                    "referralSource",
                    e.target.value,
                    sessionId ?? undefined,
                  );
                }}
                className="w-full p-2 border border-gray-300 rounded [&>option]:bg-white [&>option]:rounded-lg bg-white"
                style={{
                  fontFamily: "var(--font-montserrat)",
                  background: "#F9F6F4",
                }}
              >
                <option value="" disabled>
                  Select a source
                </option>
                {REFERRAL_SOURCES.map((source, index) => (
                  <option key={source} value={source} className="rounded-lg">
                    {source}
                  </option>
                ))}
              </select>
            </div>

            <div className="space-y-2">
              <label
                htmlFor="referralCode"
                className="text-sm font-medium text-gray-700"
              >
                Referral code (optional)
              </label>
              <Input
                id="referralCode"
                placeholder="If you have a referral code, add it here"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
                className="w-full placeholder:text-gray-400 placeholder:text-sm"
              />
            </div>
          </div>
        </motion.div>
      </div>

      <div className="flex justify-between gap-4 pt-4 sticky bottom-4 bg-white">
        <Button
          type="button"
          variant="outline"
          onClick={handleBack}
          className="w-24 p-2 border border-muted-foreground hover:bg-gray-50"
        >
          Back
        </Button>
        <Button
          type="submit"
          className="w-24 p-2 border border-[var(--primary)] bg-[var(--primary)] text-[var(--primary-foreground)] shadow-sm hover:shadow-md transition-all duration-300"
          disabled={!referralSource}
        >
          Next
        </Button>
      </div>
    </form>
  );
};
