import React, { useEffect, useState } from "react";
import { Button } from "../../../components/ui/button";
import { Textarea } from "../../../components/ui/textarea";
import { useFormStore } from "../../../store/formStore";
import { motion } from "framer-motion";
import useDebounce from "../../../hooks/useDebounce";
import { useSearchParams } from "react-router-dom";
import { updateOnboardingResponses } from "../../../services/userService";

export const PersonalPreferences = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");
  const { formData, setOnboardingField, nextStep, prevStep } = useFormStore();

  const [aboutYourself, setAboutYourself] = useState(
    formData.onboardingResponses.aboutYourself,
  );
  const [partnerPreferences, setPartnerPreferences] = useState(
    formData.onboardingResponses.partnerPreferences,
  );

  const debouncedValues = {
    aboutYourself: useDebounce(aboutYourself, 500),
    partnerPreferences: useDebounce(partnerPreferences, 500),
  };

  useEffect(() => {
    // Check each field and update if the debounced value is different from formData
    Object.entries(debouncedValues).forEach(([field, value]) => {
      if (
        value !==
        formData.onboardingResponses[
          field as keyof typeof formData.onboardingResponses
        ]
      ) {
        setOnboardingField(
          field as keyof typeof formData.onboardingResponses,
          value,
          sessionId ?? undefined,
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.values(debouncedValues)]); // Dependencies are all debounced values

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Trim fields but don't require them
    const aboutText = aboutYourself?.trim() || "";
    const preferencesText = partnerPreferences?.trim() || "";

    // Update fields with trimmed values
    setOnboardingField("aboutYourself", aboutText, sessionId ?? undefined);
    setOnboardingField(
      "partnerPreferences",
      preferencesText,
      sessionId ?? undefined,
    );

    if (sessionId) {
      updateOnboardingResponses(sessionId, {
        currentStep: formData.currentStep + 1,
        lastSaved: new Date().toISOString(),
      });
    }
    nextStep();
  };

  const handleBack = () => {
    if (sessionId) {
      updateOnboardingResponses(sessionId, {
        currentStep: formData.currentStep - 1,
        lastSaved: new Date().toISOString(),
      });
    }
    prevStep();
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <motion.h2
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.3 }}
        className="text-2xl font-semibold text-primary mb-6"
      >
        Personal Preferences
      </motion.h2>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className="space-y-2"
      >
        <label
          htmlFor="aboutYourself"
          className="block text-sm font-medium text-gray-700"
        >
          Tell us a bit more about yourself
        </label>
        <Textarea
          id="aboutYourself"
          value={aboutYourself}
          onChange={(e) => setAboutYourself(e.target.value)}
          placeholder="What are some things we should know about you and/or your background? What are your life goals?"
          className="min-h-[120px] placeholder:text-gray-400 placeholder:text-sm"
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className="space-y-2"
      >
        <label
          htmlFor="partnerPreferences"
          className="block text-sm font-medium text-gray-700"
        >
          What are you looking for in a partner?
        </label>
        <Textarea
          id="partnerPreferences"
          value={partnerPreferences}
          onChange={(e) => setPartnerPreferences(e.target.value)}
          placeholder="Tell us what you're looking for in a partner"
          className="min-h-[120px] placeholder:text-gray-400 placeholder:text-sm"
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
        className="flex justify-between gap-4"
      >
        <Button
          type="button"
          variant="outline"
          onClick={handleBack}
          className="w-24 p-2"
        >
          Back
        </Button>
        <Button
          type="submit"
          className="w-24 p-2 border border-[var(--primary)] bg-[var(--primary)] text-[var(--primary-foreground)] hover:bg-[var(--primary)]/90 transition-colors"
        >
          Next
        </Button>
      </motion.div>
    </form>
  );
};
