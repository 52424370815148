import React from "react";

interface PreferenceSelectProps {
  value: string | null;
  onChange: (value: string) => void;
}

export const PreferenceSelect = ({
  value,
  onChange,
}: PreferenceSelectProps) => {
  const optionNames = {
    male: "Men",
    female: "Women",
    everyone: "Everyone"
  };

  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-gray-700">
        Interested In *
      </label>
      <div className="grid grid-cols-3 gap-3">
        {["male", "female", "everyone"].map((option) => (
          <button
            key={option}
            type="button"
            onClick={() => onChange(option)}
            className={`flex items-center justify-center min-h-[44px] rounded-lg border transition-colors ${
              value === option
                ? "bg-primary text-primary-foreground border-primary"
                : "border-gray-200 hover:border-primary"
            }`}
          >
            {optionNames[option as keyof typeof optionNames]}
          </button>
        ))}
      </div>
    </div>
  );
};
