import constants from "../constants";
import { UserMembership } from "../types/user";
import { FormData as OnboardingFormData } from "../store/formStore";

export const getClientBio = async (sessionId: string) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/user/client_bio?session_id=${sessionId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  const data = await response.json();
  return data;
};

export const updateClientBio = async (sessionId: string, newBio: string) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/user/update_bio?session_id=${sessionId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ bio: newBio }),
    },
  );
  const data = await response.json();
  return data.bio;
};

export const updateClientProfilePhoto = async (
  sessionId: string,
  file: File,
) => {
  const formData = new FormData();
  formData.append("photo", file);

  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/user/update_profile_photo?session_id=${sessionId}`,
    {
      method: "POST",
      body: formData,
    },
  );
  const data = await response.json();
  return data;
};

export const addClientProfilePhoto = async (
  sessionId: string,
  base64String: string,
) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/user/add_profile_photo?session_id=${sessionId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ photo: base64String }),
    },
  );
  const data = await response.json();
  return data;
};

export const getUserMembership = async (
  sessionId: string,
): Promise<UserMembership> => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/user/membership?session_id=${sessionId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  const data = await response.json();
  return {
    creditBalance: data.credit_balance,
    membershipStatus: data.membership_status,
    payingStatus: data.paying_status,
  };
};

export interface ProfileData {
  user_photos_urls?: string[];
  bio?: string;
  email?: string;
  dob_month?: string;
  dob_day?: string;
  dob_year?: string;
  gender?: string;
  attracted_to?: string;
  religion?: string;
  current_location?: string;
  matching_location?: string;
  signup_location?: string;
  profession?: string;
  company?: string;
  linkedin?: string;
  must_have_attributes?: string[];
  nice_to_have_attributes?: string[];
}

export const getClientProfile = async (
  sessionId: string,
): Promise<ProfileData & UserMembership> => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/user/get_profile?session_id=${sessionId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const responseText = await response.text();
  try {
    const data = JSON.parse(responseText);
    if (data.user_photos_urls) {
      data.user_photos_urls = data.user_photos_urls.filter(Boolean);
    }
    return data;
  } catch (parseError) {
    console.error("JSON Parse Error:", parseError);
    throw new Error("Invalid JSON response from server");
  }
};

export const updateClientProfile = async (
  sessionId: string,
  newInfo: Record<string, any>,
) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/user/update_profile?session_id=${sessionId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newInfo),
    },
  );
  const data = await response.json();
  return data;
};

export const getOnboardingResponses = async (
  sessionId: string,
): Promise<OnboardingFormData> => {
  try {
    const response = await fetch(
      `${constants.BACKEND_API_ENDPOINT}/user/onboarding?session_id=${sessionId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    const responseText = await response.text();
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    try {
      return JSON.parse(responseText);
    } catch (parseError) {
      throw new Error("Invalid JSON response from server");
    }
  } catch (error) {
    console.error("Error getting onboarding responses:", error);
    throw error;
  }
};

export const updateOnboardingResponses = async (
  sessionId: string,
  newInfo: Record<string, any>,
) => {
  try {
    const response = await fetch(
      `${constants.BACKEND_API_ENDPOINT}/user/onboarding?session_id=${sessionId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newInfo),
      },
    );

    const responseText = await response.text();
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    try {
      const data = JSON.parse(responseText);
      return data;
    } catch (parseError) {
      throw new Error("Invalid JSON response from server");
    }
  } catch (error) {
    console.error("Error updating onboarding responses:", error);
    throw error;
  }
};

export const getClientPhotos = async (sessionId: string) => {
  try {
    const response = await fetch(
      `${constants.BACKEND_API_ENDPOINT}/user/photos?session_id=${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    const responseText = await response.text();
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    try {
      return JSON.parse(responseText);
    } catch (parseError) {
      throw new Error("Invalid JSON response from server");
    }
  } catch (error) {
    console.error("Error getting client photos:", error);
    throw error;
  }
};

export const addClientPhoto = async (
  sessionId: string,
  base64Strings: string[],
) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/user/photos?session_id=${sessionId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ photos: base64Strings }),
    },
  );
  const data = await response.json();
  return data;
};

export const deleteClientPhoto = async (
  sessionId: string,
  photoIndex: number,
) => {
  try {
    const response = await fetch(
      `${constants.BACKEND_API_ENDPOINT}/user/photos/${photoIndex}?session_id=${sessionId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    const responseText = await response.text();
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    try {
      return JSON.parse(responseText);
    } catch (parseError) {
      throw new Error("Invalid JSON response from server");
    }
  } catch (error) {
    console.error("Error deleting client photo:", error);
    throw error;
  }
};

export const getPhoneVerification = async (phoneNumber: string) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/user/verify/${phoneNumber}`,
  );
  const data = await response.json();
  return data;
};
