import React from "react";
import { Upload } from "lucide-react";

interface PhotoUploadProps {
  photos: string[] | null;
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPhotoRemove: (index: number) => void;
}

export const PhotoUpload = ({
  photos,
  onFileChange,
  onPhotoRemove,
}: PhotoUploadProps) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleUploadBoxClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-gray-700">
        Upload Photos * (At least 1 required, max 4)
      </label>
      <div
        onClick={handleUploadBoxClick}
        className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:border-primary transition-colors"
      >
        <div className="space-y-1 text-center">
          <Upload className="mx-auto h-12 w-12 text-gray-400" />
          <div className="flex text-sm text-gray-500">
            <span className="relative font-medium text-primary hover:text-primary/90">
              Upload photos
              <input
                ref={fileInputRef}
                id="photos"
                name="photos"
                type="file"
                accept="image/*"
                className="sr-only"
                multiple
                onChange={onFileChange}
                required={photos?.length === 0}
                disabled={photos?.length === 4}
              />
            </span>
          </div>
          <p className="text-xs text-gray-400">PNG, JPG, GIF up to 10MB</p>
        </div>
      </div>
      {photos && photos.length > 0 && (
        <div className="mt-4 grid grid-cols-2 md:grid-cols-4 gap-4">
          {Array.from(photos).map((url, index) => (
            <div key={index} className="relative aspect-square">
              <img
                src={`data:image/png;base64,${url}`}
                alt="Profile"
                className="w-full h-full object-cover rounded-lg"
              />
              <button
                type="button"
                onClick={() => onPhotoRemove(index)}
                className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors"
              >
                ×
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
