import React, { useEffect, useState } from "react";
import { Button } from "../../../components/ui/button";
import { useFormStore } from "../../../store/formStore";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { MIN_AGE } from "../../../constants/heightOptions";
import { capitalizeFirstLetter } from "../../../utils/textFormatters";
import { NameFields } from "../../../components/form-fields/NameFields";
import { EmailField } from "../../../components/form-fields/EmailField";
import { DateOfBirthField } from "../../../components/form-fields/DateOfBirthField";
import { GenderField } from "../../../components/form-fields/GenderField";
import { useSearchParams } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";
import { updateOnboardingResponses } from "../../../services/userService";

export const BasicInformation = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");
  const { formData, setField, nextStep } = useFormStore();

  const [name, setName] = useState(formData.name);
  const [lastName, setLastName] = useState(formData.lastName);
  const [email, setEmail] = useState(formData.email);
  const [gender, setGender] = useState(formData.gender);
  const [birthdate, setBirthdate] = useState(formData.birthdate);

  const debouncedValues = {
    name: useDebounce(name, 500),
    lastName: useDebounce(lastName, 500),
    email: useDebounce(email, 500),
    gender: useDebounce(gender, 500),
    birthdate: useDebounce(birthdate, 500),
  };

  useEffect(() => {
    // Check each field and update if the debounced value is different from formData
    Object.entries(debouncedValues).forEach(([field, value]) => {
      if (value !== formData[field as keyof typeof formData]) {
        setField(field as keyof typeof formData, value, sessionId ?? undefined);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.values(debouncedValues)]); // Dependencies are all debounced values

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const birthDate = new Date(birthdate);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (age < MIN_AGE || (age === MIN_AGE && monthDiff < 0)) {
      toast.error("You must be 18 or older to continue");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const trimmedEmail = email.trim();
    if (!emailRegex.test(trimmedEmail)) {
      toast.error("Please enter a valid email address");
      return;
    }

    if (!name || !lastName || !gender) {
      toast.error("Please fill in all required fields");
      return;
    }

    setField("name", capitalizeFirstLetter(name), sessionId ?? undefined);
    setField(
      "lastName",
      capitalizeFirstLetter(lastName),
      sessionId ?? undefined,
    );
    setField("email", trimmedEmail, sessionId ?? undefined);

    if (sessionId) {
      updateOnboardingResponses(sessionId, {
        currentStep: formData.currentStep + 1,
        lastSaved: new Date().toISOString(),
      });
    }
    nextStep();
  };

  const handleNameChange = (field: "name" | "lastName", value: string) => {
    const sanitizedValue = value.replace(/[^a-zA-Z\s]/g, "");
    if (field === "name") {
      setName(sanitizedValue);
    } else {
      setLastName(sanitizedValue);
    }
  };

  return (
    <div className="space-y-4">
      {name && (
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          className="text-2xl sm:text-3xl font-semibold text-center bg-gradient-to-r from-[#222222] to-[#403E43] text-transparent bg-clip-text"
        >
          Welcome, {capitalizeFirstLetter(name)}.
        </motion.h1>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className="space-y-1"
        >
          <motion.h2
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3 }}
            className="text-lg font-medium text-[#403E43] mb-4"
          >
            Basic Information
          </motion.h2>
        </motion.div>

        <NameFields
          name={name}
          lastName={lastName}
          handleNameChange={handleNameChange}
          setField={(field, value) =>
            field === "name" ? setName(value) : setLastName(value)
          }
        />

        <EmailField
          email={email}
          setField={(field, value) => setEmail(value)}
        />

        <GenderField
          gender={gender}
          setField={(field, value) =>
            setGender(value as "male" | "female" | "other" | null)
          }
        />

        <DateOfBirthField
          birthdate={birthdate}
          setField={(field, value) => setBirthdate(value)}
        />

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
          className="flex justify-end gap-4 pt-2"
        >
          <Button
            type="submit"
            disabled={!name || !lastName || !gender || !birthdate || !email}
            className="w-24 p-2 border border-[var(--primary)] bg-[var(--primary)] text-[var(--primary-foreground)] hover:bg-[var(--primary)]/90 transition-colors"
          >
            Next
          </Button>
        </motion.div>
      </form>
    </div>
  );
};
