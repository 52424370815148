import React, { FC, useEffect, useState } from "react";
import useUser from "../../hooks/useUser";
import { useSearchParams } from "react-router-dom";
import { Header } from "../../components/Header";
import { XMarkIcon, PlusIcon, PencilIcon, CheckCircleIcon, SparklesIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { getClientProfile } from "../../services/userService";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../components/ui/dialog";
import { RadioGroup, RadioGroupItem } from "../../components/ui/radio-group";
import { Label } from '../../components/ui/label';

const planToLabel = (plan: string) => {
  switch (plan) {
    case "intro_plan":
      return "Intro";
    case "standard_plan":
      return "Standard";
  }
};

const ClientProfilePage: FC = () => {
  const { addProfilePhoto, updateProfile } =
    useUser();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");

  type Preference = {
    id: string;
    text: string;
    isMustHave: boolean;
  };

  const [preferences, setPreferences] = useState<Preference[]>([
    { id: '1', text: 'Kind and ambitious', isMustHave: true }
  ]);
  const [newPreference, setNewPreference] = useState('');
  const [preferenceType, setPreferenceType] = useState<'must-have' | 'nice-to-have' | ''>('');
  const [editingPreference, setEditingPreference] = useState<{
    id: string;
    type: 'must-have' | 'nice-to-have';
  } | null>(null);
  const [editingId, setEditingId] = useState<string | null>(null);

  const [photos, setPhotos] = useState<Array<{ id: string; url: string }>>([]);
  const [photoLoadingStates, setPhotoLoadingStates] = useState<Record<string, boolean>>({});

  const [userBio, setUserBio] = useState('');
  const [email, setEmail] = useState('');
  const [birthMonth, setBirthMonth] = useState('');
  const [birthDay, setBirthDay] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [gender, setGender] = useState('');
  const [attractedTo, setAttractedTo] = useState('');
  const [religion, setReligion] = useState('');
  const [currentLocation, setCurrentLocation] = useState('');
  const [hometown, setHometown] = useState('');
  const [profession, setProfession] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [linkedinProfile, setLinkedinProfile] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [profileData, setProfileData] = useState<any>(null);
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<string>('');
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);

  // 1. Implement progressive loading
  const [isLoadingPhotos, setIsLoadingPhotos] = useState(true);
  const [isLoadingBasicInfo, setIsLoadingBasicInfo] = useState(true);

  // Add these new states
  const [showUpgradeHint, setShowUpgradeHint] = useState(false);
  const [upgradeHintLocation, setUpgradeHintLocation] = useState<'photos' | 'preferences' | null>(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!sessionId) {
        setError('No session ID found');
        setIsLoading(false);
        return;
      }

      try {
        setIsLoadingBasicInfo(true);
        const data = await getClientProfile(sessionId);
        
        // Update basic info
        setProfileData(data);
        setUserBio(data.bio || '');
        setEmail(data.email || '');
        setBirthMonth(data.dob_month || '');
        setBirthDay(data.dob_day || '');
        setBirthYear(data.dob_year || '');
        setGender(data.gender || '');
        setAttractedTo(data.attracted_to || '');
        setReligion(data.religion || '');
        setCurrentLocation(data.matching_location || data.current_location || '');
        setHometown(data.signup_location || '');
        setProfession(data.profession || '');
        setCompanyName(data.company || '');
        setLinkedinProfile(data.linkedin || '');
        setPreferences([
          ...(data.must_have_attributes || []).map((text: string) => ({
            id: crypto.randomUUID(),
            text,
            isMustHave: true
          })),
          ...(data.nice_to_have_attributes || []).map((text: string) => ({
            id: crypto.randomUUID(),
            text,
            isMustHave: false
          }))
        ]);
        
        setIsLoadingBasicInfo(false);

        // Handle photos
        setIsLoadingPhotos(true);
        if (data.user_photos_urls && data.user_photos_urls.length > 0) {
          // Convert photo URLs to the format expected by the component
          const formattedPhotos = data.user_photos_urls.map((url: string, index: number) => ({
            id: (index + 1).toString(),
            url: url.includes('base64') ? url : `data:image/jpeg;base64,${url}`
          }));

          setPhotos(formattedPhotos);
        }
        setIsLoadingPhotos(false);

      } catch (error) {
        console.error('Error loading profile:', error);
        setError(error instanceof Error ? error.message : 'Failed to load profile data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfileData();
  }, [sessionId]);

  // 6. Implement image lazy loading utility
  const loadImage = (src: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = () => reject();
      img.loading = "lazy"; // Add native lazy loading
      img.src = src;
    });
  };

  // Add this function to check if user can add more items
  const canAddMore = (type: 'photos' | 'preferences') => {
    if (profileData?.paying_status !== 'paying') {
      setUpgradeHintLocation(type);
      setShowUpgradeHint(true);
      return false;
    }
    return true;
  };

  // Modify the handleAddPreference function
  const handleAddPreference = () => {
    if (!canAddMore('preferences')) return;
    
    if (preferences.length >= 3 && profileData?.membership_status !== 'elite_plan') {
      setShowUpgradeDialog(true);
      setSelectedPlan('1000'); // Pre-select Elite plan
      return;
    }

    if (newPreference.trim() && preferenceType) {
      setPreferences(prev => [...prev, {
        id: Date.now().toString(),
        text: newPreference.trim(),
        isMustHave: preferenceType === 'must-have'
      }]);
      setNewPreference('');
      setPreferenceType('');
    }
  };

  const handleDeletePreference = (id: string) => {
    setPreferences(prev => prev.filter(pref => pref.id !== id));
    if (editingId === id) {
      setEditingId(null);
    }
  };

  const handleEditClick = (preference: Preference) => {
    if (editingId === preference.id) {
      setEditingId(null);
      setEditingPreference(null);
    } else {
      setEditingId(preference.id);
      setNewPreference(preference.text);
      setEditingPreference({
        id: preference.id,
        type: preference.isMustHave ? 'must-have' : 'nice-to-have'
      });
    }
  };

  const handleUpdatePreference = (id: string) => {
    if (editingPreference?.type) {
      setPreferences(prev => prev.map(pref =>
        pref.id === id
          ? { ...pref, text: newPreference, isMustHave: editingPreference.type === 'must-have' }
          : pref
      ));
      setEditingId(null);
      setEditingPreference(null);
      setNewPreference('');
    }
  };

  const handleDeletePhoto = (photoId: string) => {
    setPhotos(prev => {
      // First, mark the photo as empty
      const updatedPhotos = prev.map(photo =>
        photo.id === photoId ? { ...photo, url: '' } : photo
      );
      
      // Then, reorder photos to keep them left-aligned
      const filledPhotos = updatedPhotos.filter(photo => photo.url);
      const emptyPhotos = updatedPhotos.filter(photo => !photo.url);
      
      // Combine filled and empty photos, maintaining the total count
      return [...filledPhotos, ...emptyPhotos].map((photo, index) => ({
        id: (index + 1).toString(),
        url: photo.url
      }));
    });
  };

  // Modify the handleFileUpload function
  const handleFileUpload = async (photoId: string, file: File) => {
    if (!canAddMore('photos')) return;
    
    if (photos.filter(p => p.url).length >= 3 && profileData?.membership_status !== 'elite_plan') {
      setShowUpgradeDialog(true);
      setSelectedPlan('1000'); // Pre-select Elite plan
      return;
    }
    
    if (!sessionId) return;
    
    try {
      setPhotoLoadingStates(prev => ({ ...prev, [photoId]: true }));
      
      // Compress image before uploading
      const compressedFile = await compressImage(file);
      const reader = new FileReader();
      
      reader.onload = async (e) => {
        const base64String = (e.target?.result as string)?.split(',')[1];
        
        try {
          // Update UI immediately with local file
          const localUrl = URL.createObjectURL(compressedFile);
          setPhotos(prev => prev.map(photo =>
            photo.id === photoId
              ? { ...photo, url: base64String, localUrl }
              : photo
          ));
          
          const response = await addProfilePhoto(sessionId, base64String);
          if (!response) {
            throw new Error('Failed to upload photo to backend');
          }
        } catch (err) {
          console.error('Error saving photo:', err);
          setPhotos(prev => prev.map(photo =>
            photo.id === photoId ? { ...photo, url: '' } : photo
          ));
          throw err;
        }
      };

      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.error('Error uploading photo:', error);
    } finally {
      setPhotoLoadingStates(prev => ({ ...prev, [photoId]: false }));
    }
  };

  // Add image compression utility
  const compressImage = async (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject(new Error('Failed to get canvas context'));
          return;
        }
        
        // Calculate new dimensions while maintaining aspect ratio
        const maxWidth = 1200;
        const maxHeight = 1200;
        let width = img.width;
        let height = img.height;
        
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }
        
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Failed to compress image'));
            return;
          }
          resolve(new File([blob], file.name, {
            type: 'image/jpeg',
            lastModified: Date.now(),
          }));
        }, 'image/jpeg', 0.8);
      };
      img.onerror = () => reject(new Error('Failed to load image'));
    });
  };

  const handleSaveProfile = async () => {
    if (!sessionId) {
      return;
    }

    const body = JSON.stringify({
      user_photos_urls: photos.filter(photo => photo.url).map(photo => photo.url),
      bio: userBio,
      email: email,
      dob_month: birthMonth,
      dob_day: birthDay,
      dob_year: birthYear,
      gender: gender,
      target_gender: attractedTo,
      religion: religion,
      current_location: currentLocation,
      signup_location: hometown,
      profession: profession,
      company: companyName,
      linkedin: linkedinProfile
    });
    
    const must_have_attributes = preferences.filter(p => p.isMustHave).map(p => p.text);
    const nice_to_have_attributes = preferences.filter(p => !p.isMustHave).map(p => p.text);
    const updatedProfile = {
      ...JSON.parse(body),
      must_have_attributes,
      nice_to_have_attributes
    };

    try {
      await updateProfile(sessionId, updatedProfile);
      setShowSaveConfirmation(true);
      
      // Use a single timeout that auto-hides the confirmation
      const timeoutId = setTimeout(() => {
        setShowSaveConfirmation(false);
      }, 2000);

      // Clean up the timeout if the component unmounts or user interacts
      return () => clearTimeout(timeoutId);
    } catch (error) {
      console.error('Error saving profile:', error);
    }
  };

  const handleConfirmUpgrade = async () => {
    // TODO: Implement upgrade logic
    setShowUpgradeDialog(false);
    setSelectedPlan('');
  };

  const renderPhoto = (photo: { id: string; url: string }) => {
    if (!photo.url) return null;
    
    return (
      <div className="relative group">
        <img
          src={photo.url}
          alt={`Photo ${photo.id}`}
          className="w-full h-full object-cover rounded-lg"
          loading="lazy"
        />
        <button
          onClick={() => handleDeletePhoto(photo.id)}
          className="absolute top-2 right-2 p-1 bg-white/80 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
        >
          <XMarkIcon className="w-4 h-4 text-black" />
        </button>
      </div>
    );
  };

  const ProfileSkeleton = () => (
    <div className="bg-white rounded-2xl shadow-sm border border-[#E8E6E4]">
      <section className="p-4 sm:p-8 border-b border-[#E8E6E4]">
        <h2 className="text-xl font-medium mb-4 sm:mb-6 font-canela">Photos</h2>
        <div className="grid grid-cols-3 gap-3 sm:gap-6">
          {Array(3).fill(null).map((_, index) => (
            <div key={index} className="aspect-[4/3] bg-gray-200 rounded-lg animate-pulse" />
          ))}
        </div>
      </section>

      <section className="p-4 sm:p-8 border-b border-[#E8E6E4]">
        <h2 className="text-xl font-medium mb-4 sm:mb-6 font-canela">Bio</h2>
        <div className="h-36 sm:h-48 bg-gray-200 rounded-lg animate-pulse" />
      </section>

      <section className="p-4 sm:p-8 border-b border-[#E8E6E4]">
        <h2 className="text-xl font-medium mb-4 sm:mb-6 font-canela">Looking For</h2>
        <div className="space-y-4">
          {Array(3).fill(null).map((_, index) => (
            <div key={index} className="h-16 bg-gray-200 rounded-lg animate-pulse" />
          ))}
        </div>
      </section>

      <section className="p-4 sm:p-8">
        <h2 className="text-xl font-medium mb-4 sm:mb-6 font-canela">Personal Information</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {Array(8).fill(null).map((_, index) => (
            <div key={index} className="space-y-2">
              <div className="h-4 w-20 bg-gray-200 rounded animate-pulse" />
              <div className="h-10 bg-gray-200 rounded-lg animate-pulse" />
            </div>
          ))}
        </div>
      </section>
    </div>
  );

  // 7. Implement skeleton loading for photos
  const PhotoSkeleton = () => (
    <div className="aspect-[4/3] bg-gray-100 rounded-lg animate-pulse" />
  );

  // Add this component for the upgrade hint
  const UpgradeHint = ({ type }: { type: 'photos' | 'preferences' }) => (
    <div className="absolute -top-12 left-1/2 transform -translate-x-1/2 bg-black text-white px-4 py-2 rounded-lg shadow-lg text-sm z-10 animate-bounce">
      <div className="relative">
        <button 
          onClick={() => setShowUpgradeHint(false)}
          className="absolute -top-2 -right-2 text-white/80 hover:text-white"
        >
          <XMarkIcon className="w-4 h-4" />
        </button>
        <p>
          {type === 'photos' 
            ? 'Add more photos to increase your matches!' 
            : 'Define your perfect match to improve compatibility!'}
        </p>
        <button
          onClick={() => {
            setShowUpgradeDialog(true);
            setShowUpgradeHint(false);
          }}
          className="mt-2 w-full bg-white text-black px-3 py-1 rounded-md hover:bg-gray-100"
        >
          Upgrade Now
        </button>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-[#F9F6F4]">
      <Header sessionId={sessionId} />
      <div className="max-w-4xl mx-auto pb-24 px-4 sm:px-6 lg:px-0">
        <h1 
          style={{ fontFamily: 'Canela' }} 
          className="text-2xl md:text-3xl mb-4 md:mb-8 mt-4 md:mt-8 text-black px-2 sm:px-0"
        >
          Your Profile
        </h1>

        {isLoadingBasicInfo ? (
          <ProfileSkeleton />
        ) : error ? (
          <div className="text-red-600 p-4 sm:p-6 text-center">
            {error}
          </div>
        ) : (
          <div className="bg-white rounded-2xl shadow-sm border border-[#E8E6E4]">
            <section className="p-4 sm:p-8 border-b border-[#E8E6E4]">
              <h2 className="text-xl font-medium mb-4 sm:mb-6 font-canela">Photos</h2>
              <div className="grid grid-cols-3 gap-3 sm:gap-6">
                {isLoadingPhotos ? (
                  Array(3).fill(null).map((_, index) => (
                    <PhotoSkeleton key={index} />
                  ))
                ) : photos.length > 0 ? (
                  photos.map(photo => (
                    <div key={photo.id} className="aspect-[4/3] transition-transform hover:scale-[1.02] duration-200">
                      {renderPhoto(photo)}
                    </div>
                  ))
                ) : (
                  <div className="col-span-3 text-center text-gray-500">
                    No photos available
                  </div>
                )}
              </div>
            </section>

            <section className="p-4 sm:p-8 border-b border-[#E8E6E4]">
              <h2 className="text-xl font-medium mb-4 sm:mb-6 font-canela">Bio</h2>
              <div className="relative">
                <textarea
                  value={userBio}
                  onChange={(e) => setUserBio(e.target.value)}
                  className="w-full p-3 sm:p-4 border border-[#E8E6E4] rounded-xl resize-none pr-10 focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-0.5 focus:outline-none bg-[#F9F6F4] h-36 sm:h-48 transition-colors duration-200"
                  style={{ background: '#F9F6F4' }}
                  placeholder="Tell us about yourself..."
                />
              </div>
            </section>

            <section className="p-4 sm:p-8 border-b border-[#E8E6E4]">
              <h2 className="text-xl font-medium mb-4 sm:mb-6 font-canela">Looking For</h2>
              <div className="space-y-3 sm:space-y-4 bg-[#F9F6F4] p-3 sm:p-6 rounded-xl">
                {preferences.map(preference => (
                  <div 
                    key={preference.id} 
                    className="bg-white p-3 sm:p-4 rounded-xl shadow-sm border border-[#E8E6E4] transition-all duration-200 hover:shadow-md break-words"
                  >
                    {editingId === preference.id ? (
                      <div className="w-full">
                        <input
                          type="text"
                          value={newPreference}
                          onChange={(e) => setNewPreference(e.target.value)}
                          className="w-full p-2 border border-[#E8E6E4] rounded-lg bg-[#F9F6F4]"
                        />
                        <div className="flex flex-col sm:flex-row sm:items-center justify-between mt-3 space-y-2 sm:space-y-0">
                          <div className="flex flex-wrap gap-3 sm:gap-6">
                            <label className="flex items-center gap-2 whitespace-nowrap">
                              <input
                                type="radio"
                                name={`edit-preference-type-${preference.id}`}
                                value="must-have"
                                checked={editingPreference?.type === 'must-have'}
                                onChange={() => setEditingPreference({ id: preference.id, type: 'must-have' })}
                              />
                              <span>Must Have</span>
                            </label>
                            <label className="flex items-center gap-2 whitespace-nowrap">
                              <input
                                type="radio"
                                name={`edit-preference-type-${preference.id}`}
                                value="nice-to-have"
                                checked={editingPreference?.type === 'nice-to-have'}
                                onChange={() => setEditingPreference({ id: preference.id, type: 'nice-to-have' })}
                              />
                              <span>Nice to Have</span>
                            </label>
                          </div>
                          <button
                            className="w-full sm:w-auto px-4 py-1 bg-black text-white rounded-lg hover:bg-gray-800"
                            onClick={() => handleUpdatePreference(preference.id)}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-2">
                        <span className="break-words">{preference.text}</span>
                        <div className="flex items-center gap-2 self-end sm:self-auto">
                          <span className="text-xs bg-[#F9F6F4] px-2 py-1 rounded whitespace-nowrap">
                            {preference.isMustHave ? 'Must Have' : 'Nice to Have'}
                          </span>
                          <button
                            className="p-1 text-[#8A7B6C] hover:text-[#635A4F]"
                            onClick={() => handleEditClick(preference)}
                          >
                            <PencilIcon className="w-4 h-4" />
                          </button>
                          <button
                            className="p-1 text-[#8A7B6C] hover:text-red-500"
                            onClick={() => handleDeletePreference(preference.id)}
                          >
                            <XMarkIcon className="w-4 h-4" />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ))}

                <div className="bg-white p-3 sm:p-4 rounded-xl shadow-sm border border-[#E8E6E4] mt-4 sm:mt-6">
                  <input
                    type="text"
                    className="w-full p-2 sm:p-3 border border-[#E8E6E4] rounded-xl bg-[#F9F6F4] transition-colors duration-200"
                    placeholder="Add new preference"
                    value={newPreference}
                    onChange={(e) => setNewPreference(e.target.value)}
                  />
                  <div className="flex flex-col sm:flex-row sm:items-center justify-between mt-3 space-y-2 sm:space-y-0">
                    <div className="flex flex-wrap gap-3 sm:gap-6">
                      <label className="flex items-center gap-2 whitespace-nowrap">
                        <input
                          type="radio"
                          name="new-preference-type"
                          value="must-have"
                          checked={preferenceType === 'must-have'}
                          onChange={(e) => setPreferenceType(e.target.value as 'must-have')}
                        />
                        <span>Must Have</span>
                      </label>
                      <label className="flex items-center gap-2 whitespace-nowrap">
                        <input
                          type="radio"
                          name="new-preference-type"
                          value="nice-to-have"
                          checked={preferenceType === 'nice-to-have'}
                          onChange={(e) => setPreferenceType(e.target.value as 'nice-to-have')}
                        />
                        <span>Nice to Have</span>
                      </label>
                    </div>
                    <button
                      className="w-full sm:w-auto px-4 py-1 bg-[#9F8E7D] text-white rounded-lg hover:bg-[#8A7B6C]"
                      onClick={handleAddPreference}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </section>

            <section className="p-4 sm:p-8">
              <h2 className="text-xl font-medium mb-4 sm:mb-6 font-canela">Personal Information</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-3 border border-[#E8E6E4] rounded-xl focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-0.5 focus:outline-none bg-[#F9F6F4] transition-colors duration-200"
                    placeholder="your@email.com"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Date of Birth
                  </label>
                  <div className="grid grid-cols-3 gap-4">
                    <select
                      value={birthMonth}
                      onChange={(e) => setBirthMonth(e.target.value)}
                      className="w-full p-2 border border-[#E8E6E4] rounded-lg [&>option]:bg-[#F9F6F4] [&>option]:rounded-lg focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-0.5 focus:outline-none bg-[#F9F6F4] transition-colors duration-200"
                      style={{ background: '#F9F6F4' }}
                    >
                      <option value="" disabled>Month</option>
                      {[
                        "January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"
                      ].map((month, index) => (
                        <option key={month} value={index + 1} className="rounded-lg">
                          {month}
                        </option>
                      ))}
                    </select>
                    <select
                      value={birthDay}
                      onChange={(e) => setBirthDay(e.target.value)}
                      className="w-full p-2 border border-[#E8E6E4] rounded-lg [&>option]:bg-[#F9F6F4] [&>option]:rounded-lg focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-0.5 focus:outline-none bg-[#F9F6F4] transition-colors duration-200"
                      style={{ background: '#F9F6F4' }}
                    >
                      <option value="" disabled>Day</option>
                      {[...Array(31)].map((_, i) => (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}
                        </option>
                      ))}
                    </select>
                    <select
                      value={birthYear}
                      onChange={(e) => setBirthYear(e.target.value)}
                      className="w-full p-2 border border-[#E8E6E4] rounded-lg [&>option]:bg-[#F9F6F4] [&>option]:rounded-lg focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-0.5 focus:outline-none bg-[#F9F6F4] transition-colors duration-200"
                      style={{ background: '#F9F6F4' }}
                    >
                      <option value="" disabled>Year</option>
                      {[...Array((new Date()).getFullYear() - 1939)].map((_, i) => {
                        const year = (new Date()).getFullYear() - i;
                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Gender
                  </label>
                  <select
                    value={gender || ""}
                    onChange={(e) => setGender(e.target.value)}
                    className="w-full p-2 border border-[#E8E6E4] rounded-lg [&>option]:bg-[#F9F6F4] [&>option]:rounded-lg focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-0.5 focus:outline-none bg-[#F9F6F4] transition-colors duration-200"
                    style={{ background: '#F9F6F4' }}
                  >
                    <option value="" disabled>Select gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    {/* <option value="non-binary">Non-binary</option> */}
                    <option value="other">Other</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Attracted To
                  </label>
                  <select
                    value={attractedTo}
                    onChange={(e) => setAttractedTo(e.target.value)}
                    className="w-full p-2 border border-[#E8E6E4] rounded-lg [&>option]:bg-[#F9F6F4] [&>option]:rounded-lg focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-0.5 focus:outline-none bg-[#F9F6F4] transition-colors duration-200"
                    style={{ background: '#F9F6F4' }}
                  >
                    <option value="" disabled>Select gender(s) you're attracted to</option>
                    <option value="male">Men</option>
                    <option value="female">Women</option>
                    {/* <option value="non-binary">Non-binary</option>
                    <option value="all">All Genders</option> */}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Religion
                  </label>
                  <select
                    value={religion}
                    onChange={(e) => setReligion(e.target.value)}
                    className="w-full p-2 border border-[#E8E6E4] rounded-lg [&>option]:bg-[#F9F6F4] [&>option]:rounded-lg focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-0.5 focus:outline-none bg-[#F9F6F4] transition-colors duration-200"
                    style={{ background: '#F9F6F4' }}
                  >
                    <option value="" disabled>Select your religion</option>
                    <option value="christianity">Christianity</option>
                    <option value="islam">Islam</option>
                    <option value="hinduism">Hinduism</option>
                    <option value="buddhism">Buddhism</option>
                    <option value="judaism">Judaism</option>
                    <option value="sikhism">Sikhism</option>
                    <option value="atheism">Atheism</option>
                    <option value="agnosticism">Agnosticism</option>
                    <option value="other">Other</option>
                    <option value="prefer-not-to-say">Prefer not to say</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Current Location
                  </label>
                  <input
                    value={currentLocation}
                    onChange={(e) => setCurrentLocation(e.target.value)}
                    type="text"
                    className="w-full p-2 border border-[#E8E6E4] rounded-lg bg-[#F9F6F4] transition-colors duration-200"
                    style={{ background: '#F9F6F4' }}
                    placeholder="e.g., New York, NY"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Hometown
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border border-[#E8E6E4] rounded-lg bg-[#F9F6F4] transition-colors duration-200"
                    style={{ background: '#F9F6F4' }}
                    placeholder="e.g., Chicago, IL"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Profession
                  </label>
                  <input
                    value={profession}
                    onChange={(e) => setProfession(e.target.value)}
                    type="text"
                    className="w-full p-2 border border-[#E8E6E4] rounded-lg bg-[#F9F6F4] transition-colors duration-200"
                    style={{ background: '#F9F6F4' }}
                    placeholder="e.g., Software Engineer"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Company Name
                  </label>
                  <input
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    type="text"
                    className="w-full p-2 border border-[#E8E6E4] rounded-lg bg-[#F9F6F4] transition-colors duration-200"
                    style={{ background: '#F9F6F4' }}
                    placeholder="e.g., Tech Innovations Inc."
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    LinkedIn Profile
                  </label>
                  <input
                    value={linkedinProfile}
                    onChange={(e) => setLinkedinProfile(e.target.value)}
                    type="url"
                    className="w-full p-2 border border-[#E8E6E4] rounded-lg bg-[#F9F6F4] transition-colors duration-200"
                    style={{ background: '#F9F6F4' }}
                    placeholder="e.g., https://www.linkedin.com/in/yourprofile"
                  />
                </div>

                <div className="md:col-span-2 mt-8">
                  <label className="block text-xl font-medium text-black mb-4 font-canela">
                    Membership Status
                  </label>
                  <div className="space-y-4">
                    {profileData?.paying_status === "paying" ? (
                      <>
                        <div className="text-sm text-[#766A5D]">
                          Current Tier: {planToLabel(profileData.membership_status)}
                        </div>
                        <button
                          onClick={() => setShowUpgradeDialog(true)}
                          className="w-full sm:w-auto px-8 py-3 text-lg bg-[#9F8E7D] text-white rounded-xl border border-transparent hover:bg-[#8A7B6C] transition-all duration-200 hover:-translate-y-0.5"
                        >
                          Upgrade Membership
                        </button>
                      </>
                    ) : (
                      <div className="bg-white p-4 sm:p-6 rounded-xl border border-[#E8E6E4] shadow-sm">
                        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-6">
                          <div className="space-y-3">
                            <h3 className="text-2xl sm:text-xl font-medium font-canela text-center sm:text-left">
                              Looking for a partner?
                            </h3>
                            <p className="text-[#766A5D] max-w-md text-center sm:text-left text-sm sm:text-base">
                            Join our community of successful singles and get curated matches.
                            </p>
                            <div className="flex items-center justify-center sm:justify-start gap-2 sm:gap-4">
                              <div className="flex items-center">
                                <CheckCircleIcon className="w-3 h-3 sm:w-4 sm:h-4 text-[#9F8E7D] mr-1" />
                                <span className="text-[#766A5D] text-xs sm:text-sm">Verified</span>
                              </div>
                              <div className="flex items-center">
                                <CheckCircleIcon className="w-3 h-3 sm:w-4 sm:h-4 text-[#9F8E7D] mr-1" />
                                <span className="text-[#766A5D] text-xs sm:text-sm">Curated</span>
                              </div>
                              <div className="flex items-center">
                                <CheckCircleIcon className="w-3 h-3 sm:w-4 sm:h-4 text-[#9F8E7D] mr-1" />
                                <span className="text-[#766A5D] text-xs sm:text-sm">Premium</span>
                              </div>
                            </div>
                          </div>
                          
                          <div className="relative sm:min-w-[200px]">
                            <button
                              onClick={() => {
                                setShowUpgradeDialog(true);
                                setSelectedPlan('149');
                              }}
                              className="group relative w-full"
                            >
                              {/* Animated gradient background */}
                              <div className="absolute -inset-0.5 bg-gradient-to-r from-[#9F8E7D] to-[#766A5D] rounded-xl blur opacity-50 group-hover:opacity-75 transition duration-200 animate-pulse"></div>
                              
                              {/* Button content */}
                              <div className="relative px-8 py-4 text-lg bg-black text-white rounded-xl hover:bg-gray-900 transition-all duration-200 hover:-translate-y-0.5 shadow-lg text-center">
                                <span className="font-medium">Join The Community</span>
                              </div>
                            </button>
                            
                            {/* Price indicator */}
                            <div className="absolute -bottom-7 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                              <span className="text-sm text-[#766A5D] bg-white px-3 py-1 rounded-full shadow-sm border border-[#E8E6E4]">
                                Starting at $149/month
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>

      <div className="fixed bottom-0 left-0 right-0 px-4 pb-4 sm:pb-6 pt-6 sm:pt-8 bg-gradient-to-t from-[#F9F6F4] via-[#F9F6F4]/90 to-transparent backdrop-blur-sm">
        <div className="max-w-xl mx-auto relative">
          {showSaveConfirmation && (
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 animate-fade-in">
              <div className="bg-white text-black px-6 sm:px-12 py-4 sm:py-6 rounded-xl shadow-lg border border-[#E8E6E4] flex items-center space-x-4 backdrop-blur-sm">
                <svg 
                  className="w-6 h-6 sm:w-8 sm:h-8 text-green-500" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M5 13l4 4L19 7" 
                  />
                </svg>
                <span className="font-light text-base sm:text-xl tracking-wide">Changes saved successfully!</span>
              </div>
            </div>
          )}
          <button
            onClick={handleSaveProfile}
            className="w-full h-12 sm:h-14 bg-black text-white font-medium rounded-xl hover:bg-gray-900 transition-all duration-200 hover:-translate-y-0.5 active:translate-y-0 active:bg-gray-950"
          >
            Save Changes
          </button>
        </div>
      </div>

      <Dialog open={showUpgradeDialog} onOpenChange={setShowUpgradeDialog}>
        <DialogContent className="sm:max-w-[425px] bg-[#F8F7F4] border border-[#E8E6E4] rounded-2xl">
          <DialogHeader>
            <DialogTitle className="text-2xl font-light text-center mb-4 font-canela">
              Unlock Premium Features
            </DialogTitle>
            <p className="text-center text-gray-600 mb-4">
              Join our exclusive community to access premium features and increase your chances of finding the perfect match.
            </p>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <RadioGroup value={selectedPlan || ""} onValueChange={setSelectedPlan}>
              {[
                {
                  value: "149",
                  name: "Premium Plan",
                  price: "$149/month",
                  features: [
                    "7 introductions per month",
                    "24-48 hours for a new match",
                    "Up to 5 photos",
                    "Up to 5 preferences"
                  ],
                  recommended: false
                },
                {
                  value: "1000",
                  name: "Elite Plan",
                  price: "$1000/month",
                  features: [
                    "Unlimited introductions",
                    "Instant new matches",
                    "Unlimited photos",
                    "Unlimited preferences",
                    "Priority support",
                    "Exclusive events access"
                  ],
                  recommended: true
                },
              ].map((plan) => (
                <div 
                  key={plan.value} 
                  className={`p-4 rounded-lg border-2 transition-all duration-200 ${
                    selectedPlan === plan.value 
                      ? 'border-black bg-white shadow-lg scale-[1.02]' 
                      : 'border-[#E8E6E4] bg-white/50 hover:border-gray-300'
                  } ${plan.recommended ? 'relative' : ''}`}
                >
                  {plan.recommended && (
                    <span className="absolute -top-3 left-1/2 transform -translate-x-1/2 bg-black text-white px-3 py-1 rounded-full text-xs">
                      Recommended
                    </span>
                  )}
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center">
                      <RadioGroupItem value={plan.value} id={`p${plan.value}`} />
                      <Label htmlFor={`p${plan.value}`} className="ml-2 text-lg font-medium font-montserrat">
                        {plan.name}
                      </Label>
                    </div>
                    <span className="text-lg font-medium font-montserrat">{plan.price}</span>
                  </div>
                  <ul className="list-disc list-inside text-sm text-[#8A8784] ml-6">
                    {plan.features.map((feature, index) => (
                      <li key={index} className="font-montserrat">{feature}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </RadioGroup>
          </div>
          <DialogFooter>
            <button
              onClick={handleConfirmUpgrade}
              disabled={!selectedPlan}
              className={`w-full text-base tracking-wide rounded-lg py-3
                text-white luxury-button ${
                  selectedPlan ? 'bg-black hover:bg-gray-900' : 'bg-gray-400'
                } transition-all duration-200`}
            >
              Get Started Now
            </button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Add the upgrade hint where needed */}
      {showUpgradeHint && upgradeHintLocation && (
        <UpgradeHint type={upgradeHintLocation} />
      )}
    </div>
  );
};

export default ClientProfilePage;
