import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { Button } from "../../../components/ui/button";
import { useFormStore } from "../../../store/formStore";
import {
  CheckCircle,
  ArrowLeft,
  ExternalLink,
  MessageCircle,
} from "lucide-react";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { updateOnboardingResponses } from "../../../services/userService";

export const SuccessPage = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");
  const { formData, prevStep } = useFormStore();

  useEffect(() => {
    // Simulate the call notification after 2 seconds
    const timer = setTimeout(() => {
      toast.info("We're calling you now from (415) 212-9859");
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleShare = () => {
    navigator
      .share?.({
        title: "Join me on Curate",
        text: `Hey! I just signed up for Curate. Join me using my referral code: ${formData.referralCode}`,
        url: "https://curate.date",
      })
      .catch(() => {
        // Fallback if share API fails or isn't available
        navigator.clipboard.writeText(
          `https://curate.date?ref=${formData.referralCode}`,
        );
      });
  };

  const handleBack = () => {
    if (sessionId) {
      updateOnboardingResponses(sessionId, {
        currentStep: formData.currentStep - 1,
        lastSaved: new Date().toISOString(),
      });
    }
    prevStep();
  };

  return (
    <div className="text-center space-y-6">
      <Button
        variant="outline"
        onClick={handleBack}
        className="flex p-2 items-center gap-2 text-muted-foreground hover:text-primary -ml-3"
      >
        <ArrowLeft className="w-4 h-4" />
        Back
      </Button>

      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        className="inline-flex p-3 rounded-full bg-success/10 text-success"
      >
        <CheckCircle className="w-10 h-10" />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="space-y-6 max-w-2xl mx-auto"
      >
        <div className="space-y-2">
          <h2 className="text-2xl font-semibold bg-gradient-to-r from-[#222222] to-[#403E43] text-transparent bg-clip-text">
            Application Submitted!
          </h2>
          <p className="text-base text-muted-foreground">
            Thank you, {formData.name}! We're excited to have you join Curate.
          </p>
        </div>

        <div className="bg-[#f8f8f8] rounded-2xl p-6 text-left space-y-5 border border-[#EFEFEF] shadow-[0_4px_20px_-4px_rgba(0,0,0,0.06)]">
          <h3 className="text-base font-medium text-[#222222] bg-gradient-to-r from-[#222222] to-[#403E43] bg-clip-text text-transparent">
            Next Steps
          </h3>

          <ol className="space-y-5">
            {[
              {
                title: "Complete Phone Screen",
                description:
                  "Complete a short 5-minute phone screening with our virtual assistant. ",
                highlight: true,
              },
              {
                title: "Review your Profile",
                description:
                  "Once approved, we'll text you a link to review your profile and preferences.",
              },
              {
                title: "Get your First Match!",
                description:
                  "Start receiving incredible matches tailored to your preferences :)",
              },
            ].map((step, index) => (
              <motion.li
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 + 0.2 }}
                className="flex gap-4 group"
              >
                <span className="flex-shrink-0 w-7 h-7 flex items-center justify-center rounded-full bg-gradient-to-br from-[#222222] to-[#403E43] text-white font-medium text-sm transition-transform group-hover:scale-110">
                  {index + 1}
                </span>
                <div className="space-y-1">
                  <h4 className="font-medium text-[#222222] text-base">
                    {step.title}
                  </h4>
                  {step.highlight ? (
                    <p className="text-[#555555] text-sm leading-relaxed">
                      {step.description}
                      <span className="font-medium text-[#222222]">
                        We'll give you a call now from{" "}
                      </span>
                      <span className="text-[#222222] bg-gradient-to-r from-[#222222] to-[#403E43] text-transparent bg-clip-text font-semibold">
                        (415) 212-9859
                      </span>
                      . If you miss it, you can always call us back!
                    </p>
                  ) : (
                    <p className="text-[#555555] text-sm leading-relaxed">
                      {step.description}
                    </p>
                  )}
                </div>
              </motion.li>
            ))}
          </ol>
        </div>

        <div className="space-y-2 text-center">
          <p className="text-[#403E43] text-sm font-medium">
            We're looking forward to working with you!
          </p>
          <p className="text-xs text-[#666666]">
            P.S. Add us @curate.date and @alice.from.curate on Instagram
          </p>
        </div>

        <div className="flex flex-col sm:flex-row gap-3 justify-center">
          <Button
            variant="outline"
            onClick={() => window.open("https://curate.date", "_blank")}
            className="flex p-2 items-center gap-2 bg-white hover:bg-[#f8f8f8]"
          >
            <ExternalLink className="w-4 h-4" />
            Visit Curate.date
          </Button>

          <Button
            onClick={handleShare}
            className="flex p-2 items-center gap-2 bg-[#222222] hover:bg-[#333333] text-white"
          >
            <MessageCircle className="w-4 h-4" />
            Share Curate
          </Button>
        </div>
      </motion.div>
    </div>
  );
};
