import React, { useEffect } from "react";
import { motion } from "framer-motion";
import useFitFinder from "../../../hooks/useFitFinder";
import LoadingSpinbox from "../../../components/UICore/LoadingSpinbox";
import { useFormStore } from "../../../store/formStore";
import { useSearchParams } from "react-router-dom";
import { updateOnboardingResponses } from "../../../services/userService";

export const FitFinder = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");
  const { nextStep, formData } = useFormStore();
  const {
    image1Base64,
    image2Base64,
    handleImageClick,
    progress,
    isLoading,
    image1Id,
    image2Id,
    isFinished,
  } = useFitFinder();

  useEffect(() => {
    if (isFinished) {
      if (sessionId) {
        updateOnboardingResponses(sessionId, {
          currentStep: formData.currentStep + 1,
          lastSaved: new Date().toISOString(),
        });
      }
      nextStep();
    }
  }, [isFinished, sessionId, formData.currentStep, nextStep]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 flex flex-col gap-4 p-4">
        {isLoading || !image1Id ? (
          <>
            <div className="flex-1 rounded-2xl bg-muted flex items-center justify-center">
              <LoadingSpinbox />
            </div>
            <div className="flex-1 rounded-2xl bg-muted flex items-center justify-center">
              <LoadingSpinbox />
            </div>
          </>
        ) : (
          <>
            <button
              onClick={() => handleImageClick(image1Id)}
              className="flex-1 relative overflow-hidden rounded-2xl transition-transform hover:scale-[1.02] active:scale-[0.98]"
            >
              <img
                src={`data:image/jpeg;base64,${image1Base64}`}
                alt="Option 1"
                className="w-full h-full object-cover"
              />
            </button>
            <button
              onClick={() => handleImageClick(image2Id)}
              className="flex-1 relative overflow-hidden rounded-2xl transition-transform hover:scale-[1.02] active:scale-[0.98]"
            >
              <img
                src={`data:image/jpeg;base64,${image2Base64}`}
                alt="Option 2"
                className="w-full h-full object-cover"
              />
            </button>
          </>
        )}
      </div>

      <div className="p-4 space-y-2">
        <div className="h-2 bg-muted rounded-full overflow-hidden">
          <motion.div
            className="h-full bg-primary"
            initial={{ width: 0 }}
            animate={{ width: `${progress}%` }}
            transition={{ duration: 0.3 }}
          />
        </div>
        <p className="text-sm text-muted-foreground text-center">
          {Math.round(progress)}% Complete
        </p>
      </div>
    </div>
  );
};
