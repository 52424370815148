import React, { FC, useState, useEffect, useCallback } from "react";
import { useMatches } from "../../hooks/useMatches";
// import { usePostHog } from "posthog-js/react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Match } from "../../core/models";
import { Header } from "../../components/Header";

// Enhanced loading skeleton with more realistic representation
const LoadingSkeleton: FC = () => (
  <div className="min-h-screen bg-[#FAF9F7]">
    <div className="h-16 bg-white shadow-sm animate-pulse" />{" "}
    {/* Header skeleton */}
    <main className="mx-auto px-4 py-8 bg-[#F0EEE9]">
      <div className="max-w-2xl bg-white rounded-lg shadow-lg mx-auto md:px-8 pt-4 pb-[6.5rem]">
        <div className="bg-white rounded-lg shadow-sm px-8 py-4 relative">
          <div className="h-8 bg-gray-200 rounded w-48 mx-auto mb-4 animate-pulse" />
          <div className="max-w-xl mx-auto">
            <div className="text-center space-y-4">
              <div className="h-6 bg-gray-200 rounded w-48 mx-auto animate-pulse" />
              <div className="h-6 bg-gray-200 rounded w-full animate-pulse" />
              <div className="space-y-2">
                <div className="h-4 bg-gray-200 rounded w-full animate-pulse" />
                <div className="h-4 bg-gray-200 rounded w-5/6 animate-pulse" />
                <div className="h-4 bg-gray-200 rounded w-4/6 animate-pulse" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
);

// Add this new component at the top of the file, after imports
const PhoneModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}> = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-40">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div className="fixed bottom-[120px] left-0 right-0 z-50 flex flex-col items-center">
        <div className="w-[90%] bg-white rounded-lg shadow-lg mx-auto">
          <div className="px-6 pt-4">
            <h3 className="text-md font-medium">{title}</h3>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

// Add this new component after the PhoneModal component
const SuccessModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div className="fixed inset-0 flex items-center justify-center p-8">
        <div className="bg-[#F9F6F4] w-full max-w-md rounded-lg p-10 text-center">
          <h1 className="text-2xl font-light mb-6 font-['Montserrat']">
            Wonderful - A matchmaker will start a group message so the two of
            you can connect!
          </h1>

          <div className="mt-16">
            <h2 className="text-xl font-semibold mb-6 font-serif">
              Want more matches?
            </h2>
            <button
              onClick={() =>
                (window.location.href = "https://apply.curate.date/")
              }
              className="w-full bg-white border border-black text-black font-light rounded-lg h-12 font-['Montserrat'] shadow-[0_4px_12px_rgba(0,0,0,0.1)] hover:shadow-[0_6px_16px_rgba(0,0,0,0.15)] hover:bg-black hover:text-white transition-all duration-300"
            >
              Apply to Curate
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Add this new component after the SuccessModal component
const SimpleSuccessModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  if (!isOpen) return null;

  const handleInteraction = () => {
    onClose();
    navigate("/onboarding");
  };

  return (
    <div
      className="fixed inset-0 z-50"
      onClick={handleInteraction}
      onScroll={handleInteraction}
    >
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="fixed inset-0 flex items-center justify-center p-8">
        <div className="bg-[#F9F6F4] w-full max-w-md rounded-lg p-10 text-center">
          <h1 className="text-2xl font-light mb-6 font-['Montserrat']">
            Wonderful - A matchmaker will start a group message so the two of
            you can connect!
          </h1>
        </div>
      </div>
    </div>
  );
};

const IntroductionRequestPageNoPhoto: FC = () => {
  // const posthog = usePostHog();
  const [searchParams] = useSearchParams();
  const matchId = searchParams.get("matchId");
  const navigate = useNavigate();
  const [isBottomBarHidden, setIsBottomBarHidden] = useState(false);
  const [, setIntroductionStatus] = useState<string | null>(null);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isSimpleSuccessModalOpen, setIsSimpleSuccessModalOpen] =
    useState(false);
  const [introInfo, setIntroInfo] = useState<{
    exists: boolean;
    user_photo: string;
    user_age: number;
    user_bio: string;
    user_location: string;
    user_name: string;
    why_this_match: Array<string>;
    id: string;
  } | null>(null);

  const {
    matches,
    isLoading,
    sessionId,
    getIntroductionStatus,
    getIntroductionInfo,
    patchIntroduction,
    getIntroduced,
  } = useMatches();

  const placeholderMatch: Match = {
    id: "match_123",
    name: "Sarah",
    age: 28,
    location: "San Francisco, CA",
    bio: "Sarah is an adventurous soul with a deep passion for outdoor exploration and creative pursuits. She spends her weekends hiking through scenic trails with her camera in hand, always seeking out the perfect shot of nature's beauty. When she's not immersed in the great outdoors, Sarah can be found exploring the city's vibrant culinary scene, eagerly trying new restaurants and expanding her palate. Her curious and enthusiastic nature drives her to constantly seek out novel experiences, whether she's mastering advanced photography techniques, discovering hidden hiking gems in the area, or sampling diverse cuisines from around the world. Sarah approaches life with an infectious zest for adventure and believes that the best experiences are those shared with someone special. She's hoping to connect with a partner who shares her adventurous spirit and appreciation for both outdoor activities and culinary discoveries. Her ideal match would be someone who's equally excited about planning weekend hiking expeditions followed by trying that new fusion restaurant everyone's been talking about.",
    headline:
      "Nature-loving adventurer seeking companion for culinary and outdoor explorations",
    description:
      "Sarah is an outgoing software engineer who enjoys outdoor activities",
    // headline: "Adventurous spirit seeking a partner in crime for life's grand journey",
    fit_description: "You both share a passion for hiking and technology",
    prospect_image:
      "https://images.squarespace-cdn.com/content/v1/616754c4a06ef45b9f50e0db/1635103628149-KWX6DSQ8FB6I8SP6XF0F/Vanessa+girl+with+the+dogs-min.jpg",
    intro_tracking_link: "https://app.example.com/intros/match_123",
    personalized_matchmaker_note:
      "Sarah is a great match for you because she shares your passion for hiking and technology",
    client_is_paying: true,
    why_this_match: [
      "You both work in technology and share a passion for software engineering",
      "Like you, Sarah is an avid hiker who loves exploring outdoor trails and discovering new hiking spots",
      "She combines adventure with culinary exploration, making her a great companion for both outdoor activities and trying new restaurants",
    ].join("\n• "),
  };

  // Memoize the current match to prevent unnecessary re-renders
  const currentMatch = React.useMemo(() => matches[0], [matches]);

  // Memoize handlers
  const toggleBottomBar = useCallback(() => {
    setIsBottomBarHidden((prev) => !prev);
  }, []);

  const handlePhoneNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.replace(/\D/g, "").slice(0, 10);
      setPhoneNumber(value);
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    if (matchId) {
      await getIntroduced(matchId, phoneNumber);
    }
    setIsPhoneModalOpen(false);
    if (isSubscribed) {
      setIsSimpleSuccessModalOpen(true);
    } else {
      setIsSuccessModalOpen(true);
    }
  }, [isSubscribed, matchId, phoneNumber, getIntroduced]);

  // Fetch introduction info when component mounts
  useEffect(() => {
    const fetchIntroInfo = async () => {
      if (matchId) {
        try {
          const info = await getIntroductionInfo(matchId);
          console.log("Fetched introInfo:", info);
          setIntroInfo(info);
          if (info.prospect_phone_number) {
            setPhoneNumber(info.prospect_phone_number);
          }
        } catch (error) {
          console.error("Error fetching introduction info:", error);
        }
      }
    };

    fetchIntroInfo();
  }, [matchId, getIntroductionInfo]);

  useEffect(() => {
    const updateIntroStatus = async () => {
      if (introInfo?.id) {
        await patchIntroduction({
          id: introInfo.id,
          status: "in progress",
        });
      }
    };

    updateIntroStatus();
  }, [introInfo?.id]);

  // Add this log to see what's in state
  console.log("Current introInfo state:", introInfo);

  // Load font only once on mount
  useEffect(() => {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "https://use.typekit.net/ild3mkn.css";
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  if (isLoading) return <LoadingSkeleton />;

  return (
    <div className="min-h-screen bg-[#FAF9F7]">
      <Header sessionId={sessionId} />

      <main className="mx-auto px-4 py-12 bg-[#F0EEE9]">
        <div className="max-w-2xl bg-white rounded-xl shadow-md mx-auto md:px-8 pt-6 pb-[1.5rem] md:pb-[1.5rem] relative">
          <div className="bg-white rounded-xl shadow-none px-8 py-6 relative">
            <h2 className="md:text-3xl text-2xl font-light text-center mb-2 acumin-regular tracking-wide">
              Meet {introInfo?.user_name}
            </h2>
            <div className="max-w-xl mx-auto">
              <div className="text-center">
                <p className="text-lg font-light text-gray-600 mb-3 acumin-regular">
                  {introInfo?.user_location} | {introInfo?.user_age} years old
                </p>
                <h4 className="text-lg md:text-xl font-semibold mb-2 text-left acumin-regular">
                  {introInfo?.user_name}'s story
                </h4>
                <p className="text-base font-medium text-gray-600 mb-8 font-light text-left acumin-regular leading-relaxed">
                  {introInfo?.user_bio}
                </p>
              </div>

              <div>
                <h4 className="text-lg md:text-xl mb-2.5 font-semibold acumin-regular tracking-tight">
                  Why we selected this match
                </h4>
                <div className="text-base font-medium md:text-md text-gray-600 mb-8 font-light acumin-regular">
                  <ul className="list-none space-y-2 md:space-y-3">
                    {(introInfo?.why_this_match
                      ? Array.isArray(introInfo.why_this_match)
                        ? introInfo.why_this_match
                        : []
                      : []
                    )
                      .filter(
                        (point): point is string => typeof point === "string",
                      )
                      .map((point, index) => (
                        <li
                          key={index}
                          className="flex items-start py-1 md:py-1.5 hover:bg-gray-50 rounded-lg px-2 transition-colors duration-200"
                        >
                          <span className="text-gray-400 mr-3 text-lg">•</span>
                          <span className="leading-relaxed tracking-wide text-gray-700">
                            {point.replace("• ", "").trim()}
                          </span>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          onClick={toggleBottomBar}
          className={`fixed bottom-0 left-0 right-0 px-4 pb-8 pt-8 transition-all duration-300 cursor-pointer z-50
            ${
              isBottomBarHidden
                ? 'translate-y-full before:content-[""] before:fixed before:bottom-0 before:left-0 before:right-0 before:h-[50vh] before:bg-transparent before:cursor-pointer before:z-10'
                : "translate-y-0"
            } bg-gradient-to-t from-[#FAF9F7] via-[#FAF9F7]/90 to-transparent backdrop-blur-sm`}
        >
          <div className="max-w-xl mx-auto space-y-3">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsPhoneModalOpen(true);
              }}
              className="w-full h-14 bg-black text-white font-medium rounded-xl 
                hover:bg-gray-900 transition-all duration-500 shadow-lg
                animate-float-subtle hover:translate-y-[-2px] hover:shadow-xl
                transform-gpu"
              style={{ fontFamily: "Montserrat" }}
            >
              Get Introduced
            </button>
          </div>
        </div>
      </main>

      {isPhoneModalOpen && (
        <>
          <PhoneModal
            isOpen={isPhoneModalOpen}
            onClose={() => setIsPhoneModalOpen(false)}
            title="Enter your phone number"
          >
            <div className="py-4 px-6 bg-white rounded-xl">
              <input
                type="tel"
                placeholder="(555) 123-4567"
                className="w-full p-3 border-b-2 border-gray-200 mb-6 focus:outline-none focus:border-gray-800 transition-colors duration-200"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
              <label className="flex items-center gap-2 mb-4">
                <input
                  type="checkbox"
                  className="rounded accent-black"
                  checked={isSubscribed}
                  onChange={(e) => setIsSubscribed(e.target.checked)}
                />
                <span className="text-xs">
                  Sign me up to receive even more Curate matches!
                </span>
              </label>
            </div>
          </PhoneModal>
          <div className="fixed bottom-0 left-0 right-0 z-50 px-4 pb-6 bg-gradient-to-t from-[#F9F6F4] via-[#F9F6F4]/80 to-transparent">
            <div className="max-w-xl mx-auto">
              <button
                onClick={handleSubmit}
                className="w-full h-14 font-medium rounded-lg luxury-button luxury-button-primary bg-black text-white hover:bg-gray-900"
                style={{ fontFamily: "Montserrat" }}
              >
                Submit
              </button>
            </div>
          </div>
        </>
      )}

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
      />
      <SimpleSuccessModal
        isOpen={isSimpleSuccessModalOpen}
        onClose={() => setIsSimpleSuccessModalOpen(false)}
      />
    </div>
  );
};

// Memoize the entire page component
export default React.memo(IntroductionRequestPageNoPhoto);
