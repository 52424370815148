import React, { useState } from "react";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { useFormStore } from "../../../store/formStore";
import { cn } from "../../../lib/utils";

const isValidUSPhone = (phone: string) => {
  const digitsOnly = phone.replace(/\D/g, "");
  if (digitsOnly.length !== 11) return false;
  if (!digitsOnly.startsWith("1")) return false;
  const areaCode = digitsOnly.substring(1, 2);
  if (areaCode === "0" || areaCode === "1") return false;
  return true;
};

const formatPhoneNumber = (value: string) => {
  const digits = value.replace(/\D/g, "");

  // Always ensure it starts with +1
  if (!digits.startsWith("1")) {
    return "+1";
  }

  // Format the rest of the number
  if (digits.length <= 1) return "+1";
  if (digits.length <= 4) return `+1 (${digits.slice(1)}`;
  if (digits.length <= 7)
    return `+1 (${digits.slice(1, 4)}) ${digits.slice(4)}`;
  return `+1 (${digits.slice(1, 4)}) ${digits.slice(4, 7)}-${digits.slice(
    7,
    11,
  )}`;
};

export const PhoneInput = () => {
  const { formData, setField, nextStep } = useFormStore();
  const [input, setInput] = useState(formData.phoneNumber || "+1");
  const [error, setError] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    // Remove any non-digit characters except the leading +
    value = value.replace(/[^\d+]/g, "");

    // Ensure it starts with +1 and hasn't exceeded max length
    if (!value.startsWith("+")) value = "+" + value;
    if (!value.startsWith("+1")) value = "+1";

    // Format the number
    const formatted = formatPhoneNumber(value);
    setInput(formatted);

    // Clear error if input is being modified
    setError(null);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!isValidUSPhone(input)) {
      if (input.length < 15) {
        setError("Please enter a complete phone number");
      } else {
        const areaCode = input.replace(/\D/g, "").substring(1, 2);
        if (areaCode === "0" || areaCode === "1") {
          setError("Area code cannot start with 0 or 1");
        } else {
          setError("Please enter a valid US phone number");
        }
      }
      return;
    }

    setField("phoneNumber", input);
    nextStep();
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-2">
        <label
          htmlFor="phone"
          className="block text-sm font-medium text-gray-700"
        >
          Enter your Phone Number
        </label>
        <div className="space-y-2">
          <Input
            id="phone"
            type="tel"
            value={input}
            onChange={handleChange}
            className={cn(
              "w-full p-3 text-lg tracking-wide font-medium",
              error ? "border-red-500 focus-visible:ring-red-500" : "",
            )}
            placeholder="+1 (555) 555-5555"
          />
          {error && <p className="text-sm text-red-500 mt-1">{error}</p>}
        </div>
      </div>
      <Button
        type="submit"
        className="w-full bg-[#222222] hover:bg-[#333333] text-white py-6 rounded-xl transition-all duration-300 shadow-[0_2px_12px_-4px_rgba(0,0,0,0.2)] hover:shadow-[0_4px_16px_-2px_rgba(0,0,0,0.15)]"
      >
        Get Started
      </Button>
    </form>
  );
};
