export const capitalizeFirstLetter = (str: string) => {
  return str
    .trim()
    .toLowerCase()
    .replace(/(?:^|\s)\w/g, (letter) => letter.toUpperCase());
};

export const formatPhoneNumber = (phoneNumber: string) => {
  // Remove all non-digits
  const cleaned = phoneNumber.replace(/\D/g, "");

  // Handle different length cases
  let formattedNumber = "";

  if (cleaned.length === 10) {
    // Add +1 for 10 digit numbers
    formattedNumber = `+1 (${cleaned.slice(0, 3)}) ${cleaned.slice(
      3,
      6,
    )}-${cleaned.slice(6)}`;
  } else if (cleaned.length === 11) {
    // Remove first digit and add +1 for 11 digit numbers
    const digits = cleaned.slice(1);
    formattedNumber = `+1 (${digits.slice(0, 3)}) ${digits.slice(
      3,
      6,
    )}-${digits.slice(6)}`;
  } else if (cleaned.length === 12 && cleaned.startsWith("1")) {
    // Already has +1, just format the remaining digits
    const digits = cleaned.slice(1);
    formattedNumber = `+1 (${digits.slice(0, 3)}) ${digits.slice(
      3,
      6,
    )}-${digits.slice(6)}`;
  } else {
    // Return original if format doesn't match
    return phoneNumber;
  }

  return formattedNumber;
};
