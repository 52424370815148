import React, { useEffect, useState } from "react";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { useFormStore } from "../../../store/formStore";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { capitalizeFirstLetter } from "../../../utils/textFormatters";
import { useSearchParams } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";
import { updateOnboardingResponses } from "../../../services/userService";

export const ProfessionalInformation = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");
  const { formData, setOnboardingField, nextStep, prevStep } = useFormStore();

  const [education, setEducation] = useState(
    formData.onboardingResponses.education,
  );
  const [profession, setProfession] = useState(
    formData.onboardingResponses.profession,
  );
  const [companyName, setCompanyName] = useState(
    formData.onboardingResponses.companyName,
  );
  const [linkedinProfile, setLinkedinProfile] = useState(
    formData.onboardingResponses.linkedinProfile,
  );

  const debouncedValues = {
    education: useDebounce(education, 500),
    profession: useDebounce(profession, 500),
    companyName: useDebounce(companyName, 500),
    linkedinProfile: useDebounce(linkedinProfile, 500),
  };

  useEffect(() => {
    // Check each field and update if the debounced value is different from formData
    Object.entries(debouncedValues).forEach(([field, value]) => {
      if (
        value !==
        formData.onboardingResponses[
          field as keyof typeof formData.onboardingResponses
        ]
      ) {
        setOnboardingField(
          field as keyof typeof formData.onboardingResponses,
          value,
          sessionId ?? undefined,
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.values(debouncedValues)]); // Dependencies are all debounced values

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Format and validate fields
    const formattedEducation = capitalizeFirstLetter(education);
    const formattedProfession = capitalizeFirstLetter(profession);
    const formattedCompany = companyName
      ? capitalizeFirstLetter(companyName)
      : "";
    const trimmedLinkedin = linkedinProfile?.trim() || "";

    if (!formattedEducation || !formattedProfession) {
      toast.error("Please fill in all required fields");
      return;
    }

    // Update fields with proper formatting
    setOnboardingField("education", formattedEducation, sessionId ?? undefined);
    setOnboardingField(
      "profession",
      formattedProfession,
      sessionId ?? undefined,
    );
    setOnboardingField("companyName", formattedCompany, sessionId ?? undefined);
    setOnboardingField(
      "linkedinProfile",
      trimmedLinkedin,
      sessionId ?? undefined,
    );

    if (sessionId) {
      updateOnboardingResponses(sessionId, {
        currentStep: formData.currentStep + 1,
        lastSaved: new Date().toISOString(),
      });
    }
    nextStep();
  };

  const handleBack = () => {
    if (sessionId) {
      updateOnboardingResponses(sessionId, {
        currentStep: formData.currentStep - 1,
        lastSaved: new Date().toISOString(),
      });
    }
    prevStep();
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <motion.h2
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.3 }}
        className="text-2xl font-semibold text-primary mb-6"
      >
        Education & Professional Information
      </motion.h2>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className="space-y-2"
      >
        <label
          htmlFor="education"
          className="block text-sm font-medium text-gray-700"
        >
          Education *
        </label>
        <Input
          id="education"
          value={education}
          onChange={(e) => setEducation(e.target.value)}
          // onBlur={(e) => setEducation(capitalizeFirstLetter(e.target.value))}
          placeholder="Which college did you attend?"
          required
          className="w-full placeholder:text-gray-400 placeholder:text-sm"
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className="space-y-2"
      >
        <label
          htmlFor="profession"
          className="block text-sm font-medium text-gray-700"
        >
          Profession *
        </label>
        <Input
          id="profession"
          value={profession}
          onChange={(e) => setProfession(e.target.value)}
          // onBlur={(e) => setProfession(capitalizeFirstLetter(e.target.value))}
          placeholder="What do you do for work?"
          required
          className="w-full placeholder:text-gray-400 placeholder:text-sm"
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
        className="space-y-2"
      >
        <label
          htmlFor="companyName"
          className="block text-sm font-medium text-gray-700"
        >
          Company Name
        </label>
        <Input
          id="companyName"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          // onBlur={(e) => setCompanyName(capitalizeFirstLetter(e.target.value))}
          placeholder="Where do you work?"
          className="w-full placeholder:text-gray-400 placeholder:text-sm"
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        className="space-y-2"
      >
        <label
          htmlFor="linkedinProfile"
          className="block text-sm font-medium text-gray-700"
        >
          LinkedIn Profile
        </label>
        <Input
          id="linkedinProfile"
          value={linkedinProfile}
          onChange={(e) => setLinkedinProfile(e.target.value)}
          // onBlur={(e) => setLinkedinProfile(e.target.value.trim())}
          placeholder="Your LinkedIn URL"
          className="w-full placeholder:text-gray-400 placeholder:text-sm"
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
        className="flex justify-between gap-4"
      >
        <Button
          type="button"
          variant="outline"
          onClick={handleBack}
          className="w-24 p-2"
        >
          Back
        </Button>
        <Button
          type="submit"
          className="w-24 p-2 border border-[var(--primary)] bg-[var(--primary)] text-[var(--primary-foreground)] hover:bg-[var(--primary)]/90 transition-colors"
        >
          Next
        </Button>
      </motion.div>
    </form>
  );
};
