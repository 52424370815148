import React from "react";
import { Input } from "../ui/input";
import { motion } from "framer-motion";
import { capitalizeFirstLetter } from "../../utils/textFormatters";

interface NameFieldsProps {
  name: string;
  lastName: string;
  handleNameChange: (field: "name" | "lastName", value: string) => void;
  setField: (field: "name" | "lastName", value: string) => void;
}

export const NameFields = ({
  name,
  lastName,
  handleNameChange,
  setField,
}: NameFieldsProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.1 }}
      className="grid md:grid-cols-2 gap-4"
    >
      <div className="space-y-2">
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700"
        >
          First Name *
        </label>
        <Input
          id="name"
          value={name}
          onChange={(e) => handleNameChange("name", e.target.value)}
          placeholder="Enter your First Name"
          required
          className="w-full placeholder:text-gray-400 placeholder:text-sm"
        />
      </div>

      <div className="space-y-2">
        <label
          htmlFor="lastName"
          className="block text-sm font-medium text-gray-700"
        >
          Last Name *
        </label>
        <Input
          id="lastName"
          value={lastName}
          onChange={(e) => handleNameChange("lastName", e.target.value)}
          placeholder="Enter your Last Name"
          required
          className="w-full placeholder:text-gray-400 placeholder:text-sm"
        />
      </div>
    </motion.div>
  );
};
