import React from "react";
import { Input } from "../ui/input";
import { motion } from "framer-motion";

interface EmailFieldProps {
  email: string;
  setField: (field: "email", value: string) => void;
}

export const EmailField = ({ email, setField }: EmailFieldProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2 }}
      className="space-y-2"
    >
      <label
        htmlFor="email"
        className="block text-sm font-medium text-gray-700"
      >
        Email Address *
      </label>
      <Input
        id="email"
        type="email"
        value={email}
        onChange={(e) => setField("email", e.target.value)}
        onBlur={(e) => setField("email", e.target.value.trim())}
        placeholder="Enter your Email"
        required
        className="w-full placeholder:text-gray-400 placeholder:text-sm"
      />
    </motion.div>
  );
};
