import React, { FC, useEffect } from "react";
import { useMatches } from "../../hooks/useMatches";
import { Header } from "../../components/Header";
import { Link, useSearchParams } from "react-router-dom";
import LoadingSpinbox from "../../components/UICore/LoadingSpinbox";

const introductionStatusToLabel = (status: string) => {
  switch (status) {
    case "pending":
      return "Pending";
    case "in progress":
      return "Reached Out";
    case "in progress (followed up)":
      return "Followed Up";
    case "accepted":
      return "Accepted";
    case "rejected":
      return "Rejected";
    case "stale":
      return "Rejected";
  }
};

const Introductions: FC = () => {
  const { introductions, introductionsLoading, fetchIntroductions } =
    useMatches();

  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");

  useEffect(() => {
    fetchIntroductions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="min-h-screen bg-[#F9F6F4]">
      <Header sessionId={sessionId} />
      <main className="container max-w-4xl mx-auto px-4">
        <h1 className="text-xl md:text-2xl font-light mb-4 md:mb-8 mt-8 text-black tracking-wide font-['Montserrat']">
          Pending Introductions
        </h1>
        <div className="bg-white rounded-lg overflow-hidden coastal-shadow p-4 md:p-8 border border-taupe/20">
          <ul className="grid gap-3 sm:grid-cols-2 grid-cols-1">
            {introductionsLoading ? (
              <div className="col-span-2 text-gray-500 text-center h-[300px] flex flex-col justify-center">
                <LoadingSpinbox textBelow="Loading introductions..." />
              </div>
            ) : introductions.length === 0 ? (
              <div className="col-span-2 text-gray-500 text-center h-[300px] flex flex-col justify-center">
                No introductions yet. <br /> Ask your matchmaker for an
                introduction.
              </div>
            ) : (
              introductions.map((intro) => (
                <li key={intro.id}>
                  <div className="flex sm:flex-col bg-white shadow-sm border border-slate-200 rounded-lg w-full p-2 sm:p-3 items-center">
                    <div className="w-1/3 sm:w-full overflow-hidden rounded-md sm:h-80 flex justify-center items-center">
                      <img
                        className="w-full h-full object-cover aspect-square"
                        src={
                          `data:image/jpeg;base64,${intro.prospect_base64_image}` ||
                          "https://via.placeholder.com/80"
                        }
                        alt={intro.prospect_name}
                      />
                    </div>
                    <div className="pl-4 sm:p-5 sm:text-center">
                      <h4 className="mb-1 text-lg sm:text-xl font-light tracking-wide text-black mb-2 font-['Montserrat'] truncate">
                        {intro.prospect_name.replace(/\.$/, "")},{" "}
                        {intro.prospect_age}
                      </h4>
                      <p className="text-taupe tracking-wide text-xs sm:text-sm uppercase mb-3 sm:mb-5">
                        Status: {introductionStatusToLabel(intro.status)}
                      </p>
                      <Link
                        to={`/match?matchId=${intro.match_id}`}
                        className="inline-block text-black hover:text-taupe transition-colors tracking-wide text-xs uppercase font-bold bg-[#F5F3F0] px-3 py-2 rounded-md"
                      >
                        View Profile
                      </Link>
                    </div>
                  </div>
                </li>
              ))
            )}
          </ul>
        </div>
      </main>
    </div>
  );
};

export default Introductions;
