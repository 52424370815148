import React, { FC, useState, useEffect } from "react";
import { useMatches } from "../../hooks/useMatches";
import { usePostHog } from "posthog-js/react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Modal from "../../components/Modal";
import { Match } from "../../core/models";
import { Header } from "../../components/Header";

const introductionStatusToLabel = (status: string) => {
  switch (status) {
    case "pending":
      return "Requested Introduction";
    case "in progress":
      return "Requested Introduction";
    case "in progress (followed up)":
      return "Requested Introduction";
    case "accepted":
      return "Accepted";
    case "rejected":
      return "No mutual alignment";
    case "stale":
      return "No mutual alignment";
  }
};


const LoadingSkeleton: FC = () => (
  <div className="min-h-screen bg-[#F9F6F4]">
    <div className="h-16 bg-white shadow-sm animate-pulse" /> {/* Header skeleton */}
    
    <main className="mx-auto px-4 py-8 bg-[#E8E6E1]">
      <div className="max-w-2xl bg-white rounded-lg shadow-lg mx-auto md:px-8 pt-4 pb-[6.5rem]">
        <div className="bg-white rounded-lg shadow-sm px-8 py-4 relative">
          {/* Name skeleton */}
          <div className="h-8 bg-gray-200 rounded w-48 mx-auto mb-4 animate-pulse" />
          
          <div className="max-w-xl mx-auto">
            {/* Image skeleton */}
            <div className="aspect-[3/4] bg-gray-200 mb-6 rounded-lg overflow-hidden max-w-[200px] md:max-w-[280px] mx-auto animate-pulse" />
            
            {/* Details skeleton */}
            <div className="text-center space-y-4">
              <div className="h-6 bg-gray-200 rounded w-48 mx-auto animate-pulse" />
              <div className="h-6 bg-gray-200 rounded w-full animate-pulse" />
              <div className="space-y-2">
                <div className="h-4 bg-gray-200 rounded w-full animate-pulse" />
                <div className="h-4 bg-gray-200 rounded w-5/6 animate-pulse" />
                <div className="h-4 bg-gray-200 rounded w-4/6 animate-pulse" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
);

const MatchPage: FC = () => {
  const posthog = usePostHog();
  const [searchParams] = useSearchParams();
  const matchId = searchParams.get("matchId");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [isBottomBarHidden, setIsBottomBarHidden] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [nextMatchFeedback, setNextMatchFeedback] = useState("");

  const {
    matches,
    isLoading,
    sessionId,
    requestIntroduction,
    requestIntroError,
    requestAnotherMatchLoading,
    requestAnotherMatch,
    requestAnotherMatchSuccess,
  } = useMatches();

  // Memoize the current match to prevent unnecessary re-renders
  const currentMatch = React.useMemo(() => matches[0], [matches]);

  // Optimize image loading with lazy loading and blur placeholder
  const MatchImage = React.memo(({ match }: { match?: Match }) => {
    const [isLoading, setIsLoading] = useState(true);
    const imageUrl = match ? `data:image/jpeg;base64,${match.prospect_image}` : '';

    if (!match) return null;

    return (
      <div className="aspect-[3/4] bg-gray-200 mb-6 rounded-lg overflow-hidden max-w-[200px] md:max-w-[280px] mx-auto shadow-md relative">
        {isLoading && (
          <div className="absolute inset-0 bg-gray-200 animate-pulse" />
        )}
        <img
          src={imageUrl}
          alt={match.name}
          className={`w-full h-full object-cover transition-opacity duration-300 ${
            isLoading ? 'opacity-0' : 'opacity-100'
          }`}
          loading="lazy"
          onLoad={() => setIsLoading(false)}
          onError={(e) => {
            setIsLoading(false);
            e.currentTarget.src = "/fallback-image.jpg";
          }}
        />
      </div>
    );
  });

  const handleRequestIntroduction = async () => {
    if (!matchId) {
      console.error("No match ID available in URL");
      return;
    }

    setIsModalOpen(true);
    const success = await requestIntroduction(matchId);
    if (success) {
      posthog.capture("introduction_requested", {
        match_id: matchId,
      });
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    navigate(`/introductions?sessionId=${sessionId}`);
  };

  // Rename toggle handler
  const toggleBottomBar = () => {
    setIsBottomBarHidden(prev => !prev);
  };

  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://use.typekit.net/ild3mkn.css';
    document.head.appendChild(link);
  }, []);

  if (isLoading) return <LoadingSkeleton />;

  if (!currentMatch) {
    return (
      <div className="min-h-screen bg-[#F9F6F4] flex items-center justify-center">
        <div className="text-center p-8">
          <p className="text-xl text-gray-600">No match available</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#F9F6F4]">
      <Header sessionId={sessionId} />

      <main className="mx-auto px-4 py-8 bg-[#E8E6E1]">
        <div className="max-w-2xl bg-white rounded-lg shadow-lg mx-auto md:px-8 pt-4 pb-[6.5rem]" style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}>
          <div className="bg-white rounded-lg shadow-sm px-8 py-4 relative">
            <h2 className="md:text-2xl text-xl font-light text-center mb-4 acumin-regular">
              Meet {currentMatch.name}
            </h2>
            <div className="max-w-xl mx-auto">
              <MatchImage match={currentMatch} />

              {/* Match Details */}
              <div className="text-center">
                <p className="text-lg font-light text-gray-600 mb-3 acumin-regular">
                  {currentMatch.location} | {currentMatch.age} years old
                </p>
                <h4 className="text-lg md:text-xl font-semibold mb-2 text-left acumin-regular">
                  {currentMatch.name.split(' ')[0]}'s story
                </h4>
                <p className="text-md md:text-md text-gray-600 mb-6 font-light text-left acumin-regular">
                  {currentMatch.bio}
                </p>
              </div>

              {currentMatch.personalized_matchmaker_note && (
                <div>
                  <h4 className="font-serif text-lg mb-2 font-semibold acumin-regular">Why we selected this match</h4>
                  <p className="text-sm md:text-md text-gray-600 mb-6 font-light acumin-regular">
                    {currentMatch.personalized_matchmaker_note}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div
          onClick={toggleBottomBar}
          className={`fixed bottom-0 left-0 right-0 px-4 pb-6 pt-8 transition-all duration-300 cursor-pointer ${
            isBottomBarHidden
              ? 'translate-y-full after:content-[""] after:absolute after:top-[-40px] after:left-0 after:right-0 after:h-10'
              : 'translate-y-0'
          } bg-gradient-to-t from-[#F9F6F4] via-[#F9F6F4]/80 to-transparent`}
        >
          <div className="max-w-xl mx-auto space-y-3">
            {currentMatch.introduction_requested ? (
              <button
                disabled
                className="w-full h-14 bg-gray-400 text-white font-medium hover:scale-105 rounded-lg"
              >
                {introductionStatusToLabel(currentMatch.introduction_status || "pending")}
              </button>
            ) : (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleRequestIntroduction();
                }}
                className="w-full h-14 bg-black text-white font-medium rounded-lg luxury-button luxury-button-primary"
              >
                Get Introduced (5 credits)
              </button>
            )}

            {requestIntroError && (
              <p className="text-red-500 text-sm font-normal text-center">{requestIntroError}</p>
            )}

            {!currentMatch.introduction_requested && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowRequestModal(true);
                }}
                className="w-full h-14 border border-black/10 bg-white text-black font-medium rounded-lg hover:bg-gray-50 hover:scale-105 transition-all duration-300"
                style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
              >
                Request Another Match (2 credits)
              </button>
            )}
          </div>
        </div>
      </main>

      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          handleModalClose();
          window.location.href = `/introductions${sessionId ? `?sessionId=${sessionId}` : ''}`;
        }}
        title="Introduction Requested"
      >
        <div className="text-center rounded-lg">
          <p className="text-gray-600">
            Your matchmaker will reach out to see if there is mutual interest!
          </p>
        </div>
      </Modal>

      <Modal
        isOpen={showRequestModal}
        onClose={() => {
          setShowRequestModal(false);
          window.location.href = `/introductions${sessionId ? `?sessionId=${sessionId}` : ''}`;
        }}
        title="Request Another Match"
        onConfirm={requestAnotherMatchSuccess === null ? () => {
          requestAnotherMatch(nextMatchFeedback);
          setNextMatchFeedback("");
          // posthog?.capture("request_another_match_clicked", { success: true });
        } : undefined}
        onConfirmText={requestAnotherMatchSuccess === null ? "Request Another Match (2 credits)" : undefined}>
        <div className="p-4">
          {requestAnotherMatchLoading ? (
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900 ml-auto mr-auto "></div>
          ) : requestAnotherMatchSuccess === null ? (
            <div className="flex flex-col space-y-4 text-left">
              <p>Feedback for your next match (optional)</p>
              <textarea
                className="w-full h-24 border border-gray-300 rounded-md p-2"
                value={nextMatchFeedback}
                onChange={(e) => setNextMatchFeedback(e.target.value)}
              />
            </div>
          ) : requestAnotherMatchSuccess ? (
            <p className="text-lg">
              Your matchmaker will start looking for another match!
            </p>
          ) : (
            <p className="text-lg text-red-600">
              Failed to request another match. Please try again later or
              contact support.
            </p>
          )}
        </div>
      </Modal>
    </div>
  )
};

// Memoize the entire page component
export default React.memo(MatchPage);