import React, { useEffect, useState } from "react";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { useFormStore } from "../../../store/formStore";
import { toast } from "react-toastify";
import { PhotoUpload } from "../../../components/location/PhotoUpload";
import { PreferenceSelect } from "../../../components/location/PreferenceSelect";
import { capitalizeFirstLetter } from "../../../utils/textFormatters";
import { motion } from "framer-motion";
import { useSearchParams } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  updateOnboardingResponses,
  addClientPhoto,
  deleteClientPhoto,
  getClientPhotos,
} from "../../../services/userService";

export const LocationInformation = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");
  const { formData, setField, nextStep, prevStep } = useFormStore();
  const queryClient = useQueryClient();

  const [currentLocation, setCurrentLocation] = useState(
    formData.currentLocation,
  );
  const [hometown, setHometown] = useState(formData.hometown);
  const [targetGender, setTargetGender] = useState(formData.targetGender);
  const [instagram, setInstagram] = useState(formData.instagram);
  const [isLoading, setIsLoading] = useState(false);

  const { data: photos = [] } = useQuery({
    queryKey: ["photos", sessionId],
    queryFn: () => getClientPhotos(sessionId || ""),
    enabled: !!sessionId,
  });

  const addPhotoMutation = useMutation({
    mutationFn: async (base64Strings: string[]) => {
      if (!sessionId) return;
      return await addClientPhoto(sessionId, base64Strings);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["photos"] });
    },
  });

  const deletePhotoMutation = useMutation({
    mutationFn: async (index: number) => {
      if (!sessionId) return;
      return await deleteClientPhoto(sessionId, index);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["photos"] });
    },
  });

  const debouncedValues = {
    currentLocation: useDebounce(currentLocation, 500),
    hometown: useDebounce(hometown, 500),
    targetGender: useDebounce(targetGender, 500),
    instagram: useDebounce(instagram, 500),
  };

  useEffect(() => {
    // Check each field and update if the debounced value is different from formData
    Object.entries(debouncedValues).forEach(([field, value]) => {
      if (value !== formData[field as keyof typeof formData]) {
        setField(field as keyof typeof formData, value, sessionId ?? undefined);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.values(debouncedValues)]); // Dependencies are all debounced values

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Validate required fields and format them
    const formattedLocation = capitalizeFirstLetter(currentLocation);
    const formattedHometown = capitalizeFirstLetter(hometown);
    const trimmedInstagram = instagram.trim();

    if (!formattedLocation || !formattedHometown || !targetGender) {
      toast.error("Please fill in all required fields");
      return;
    }

    // Update fields with proper formatting before proceeding
    setField("currentLocation", formattedLocation, sessionId ?? undefined);
    setField("hometown", formattedHometown, sessionId ?? undefined);
    setField("instagram", trimmedInstagram, sessionId ?? undefined);

    if (sessionId) {
      updateOnboardingResponses(sessionId, {
        currentStep: formData.currentStep + 1,
        lastSaved: new Date().toISOString(),
      });
    }
    nextStep();
  };

  const handleBack = () => {
    if (sessionId) {
      updateOnboardingResponses(sessionId, {
        currentStep: formData.currentStep - 1,
        lastSaved: new Date().toISOString(),
      });
    }
    prevStep();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // Check if total photos would exceed maximum
    if (e.target.files && e.target.files.length + (photos?.length || 0) > 4) {
      toast.error("Maximum 4 photos allowed");
      return;
    }

    // Check file sizes
    if (e.target.files) {
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes
      const oversizedFiles = Array.from(e.target.files).filter(
        (file) => file.size > maxSize,
      );

      if (oversizedFiles.length > 0) {
        toast.error("Files must be under 10MB in size");
        e.target.value = "";
        return;
      }
    }
    if (e.target.files && e.target.files.length > 0) {
      if (!sessionId) return;

      setIsLoading(true);
      const files = Array.from(e.target.files);
      const base64Promises = files.map((file) => {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = (e) => {
            const base64String = (e.target?.result as string)?.split(",")[1];
            resolve(base64String);
          };

          reader.onerror = () => reject(reader.error);
          reader.readAsDataURL(file);
        });
      });

      try {
        const base64Strings = await Promise.all(base64Promises);
        await addPhotoMutation.mutateAsync(base64Strings);
      } catch (error) {
        console.error("Error uploading photos:", error);
      } finally {
        setIsLoading(false);
      }

      e.target.value = "";
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <motion.h2
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.3 }}
        className="text-2xl font-semibold text-primary mb-6"
      >
        Location & Preferences
      </motion.h2>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className="space-y-2"
      >
        <label
          htmlFor="currentLocation"
          className="block text-sm font-medium text-gray-700"
        >
          Current Location *
        </label>
        <Input
          id="currentLocation"
          value={currentLocation}
          onChange={(e) => setCurrentLocation(e.target.value)}
          // onBlur={(e) =>
          //   setCurrentLocation(capitalizeFirstLetter(e.target.value))
          // }
          placeholder="Where do you live?"
          required
          className="w-full placeholder:text-gray-400 placeholder:text-sm"
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className="space-y-2"
      >
        <label
          htmlFor="hometown"
          className="block text-sm font-medium text-gray-700"
        >
          Hometown *
        </label>
        <Input
          id="hometown"
          value={hometown}
          onChange={(e) => setHometown(e.target.value)}
          // onBlur={(e) => setHometown(capitalizeFirstLetter(e.target.value))}
          placeholder="Where are you from?"
          required
          className="w-full placeholder:text-gray-400 placeholder:text-sm"
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
      >
        <PreferenceSelect
          value={targetGender}
          onChange={(value) =>
            setTargetGender(value as "male" | "female" | "everyone" | null)
          }
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        className="space-y-2"
      >
        <label
          htmlFor="instagram"
          className="block text-sm font-medium text-gray-700"
        >
          Instagram
        </label>
        <Input
          id="instagram"
          value={instagram}
          onChange={(e) => {
            const value = e.target.value.replace(/^@/, "");
            setInstagram(value);
          }}
          // onBlur={(e) => setInstagram(e.target.value.trim())}
          placeholder="Enter your username"
          className="w-full placeholder:text-gray-400 placeholder:text-sm"
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
      >
        <PhotoUpload
          photos={photos}
          onFileChange={handleFileChange}
          onPhotoRemove={async (index) => {
            if (!sessionId) return;
            setIsLoading(true);
            try {
              await deletePhotoMutation.mutateAsync(index);
            } catch (error) {
              console.error("Error deleting photo:", error);
            } finally {
              setIsLoading(false);
            }
          }}
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.6 }}
        className="flex justify-between gap-4 items-center"
      >
        <Button
          type="button"
          variant="outline"
          onClick={handleBack}
          className="w-24 p-2"
        >
          Back
        </Button>
        <div className="flex items-center gap-2">
          {isLoading && (
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-black"></div>
          )}
          <Button
            type="submit"
            className="w-24 p-2 border border-[var(--primary)] bg-[var(--primary)] text-[var(--primary-foreground)] hover:bg-[var(--primary)]/90 transition-colors"
          >
            Next
          </Button>
        </div>
      </motion.div>
    </form>
  );
};
