import React from "react";
import { motion } from "framer-motion";

interface GenderFieldProps {
  gender: string | null;
  setField: (field: "gender", value: string) => void;
}

export const GenderField = ({ gender, setField }: GenderFieldProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.4 }}
      className="space-y-2"
    >
      <label className="block text-sm font-medium text-gray-700">
        Your Gender *
      </label>
      <div className="grid grid-cols-3 gap-3">
        {["male", "female", "other"].map((option) => (
          <button
            key={option}
            type="button"
            onClick={() => {
              console.log("gender", gender);
              console.log("option", option);
              setField("gender", option);
            }}
            className={`p-3 rounded-lg border ${
              gender === option
                ? "bg-primary text-primary-foreground border-primary"
                : "border-gray-200 hover:border-primary"
            }`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
    </motion.div>
  );
};
